import { useEffect, useRef } from 'react';
import Button from '../common/Button';
import SendIcon from '../common/icons/Send';
import TextArea from '../common/TextArea';
import AuthButton from '../AuthButton';
import useUser, { UserStatus } from '../../hooks/useUser';

const defaultText = 'Hello, how is your';

const CompletionInputBar = ({
  value,
  onChange,
  onSubmit,
  disabled = false,
}: {
  value: string;
  onChange: (text: string) => void;
  onSubmit: (defaultText?: string) => void;
  disabled?: boolean;
}) => {
  const { status } = useUser();
  const isAuthenticated = status !== UserStatus.LoggedOut;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const newLines = value.split('\n').length;
  const newHeight = 16 + newLines * 20;
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (value) {
      onSubmit();
    } else {
      onSubmit(defaultText);
    }
  };
  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <form className="flex items-center px-10" onSubmit={handleSubmit}>
      <TextArea
        ref={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter text here and AI will help complete the sentence"
        onKeyDown={onEnterPress}
        className="resize-none bg-transparent"
        containerClassName="w-full bg-transparent border-0"
        innerContainerClassName="px-0"
        style={{ height: newHeight }}
        disabled={disabled}
        endIcon={
          value.length > 0 &&
          (!isAuthenticated ? (
            <AuthButton className="mr-2 text-sm py-1.5" />
          ) : (
            <Button
              variant="link"
              type="submit"
              className="hover:bg-theme-primary-600 hover:text-white bg-theme-neutral-200 disabled:bg-theme-neutral-200 disabled:text-theme-neutral-500 p-2 rounded-full duration-400"
              disabled={disabled}
            >
              <SendIcon size={20} />
            </Button>
          ))
        }
      />
    </form>
  );
};

export default CompletionInputBar;

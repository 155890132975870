import hyperFetch from '../utils/fetch';

export const fetchStripeCheckoutSessionURL = async (amount: number) => {
  const data = await hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/stripe/create-checkout-session`,
    { method: 'POST', body: JSON.stringify({ amount }) }
  );
  return data?.url;
};

export const fetchStripeCheckoutSessionStatus = async (sessionId: string) => {
  const data = await hyperFetch(
    `${
      import.meta.env.VITE_BACKEND_HOST
    }/stripe/session-status?session_id=${sessionId}`
  );
  return data?.status;
};

import snakeCase from 'lodash/snakeCase';
import {
  AudioInputParameters,
  ImageInputParameters,
  Model,
  TextInputParameters,
} from './types';

export type FormatFn = (
  modelName: string,
  modelUrl: string,
  inputParameters: TextInputParameters<number>,
  apiKey: string,
  messages?: ChatMessage[]
) => string;

interface ChatMessage {
  role: string;
  content: string;
}

export const findModelName = (
  model?: Model,
  currentVariant?: string | null
) => {
  const modelVariants = model?.variants;
  const selectedVariant = currentVariant || modelVariants?.[0];
  const modelName = selectedVariant || model?.model;
  return modelName;
};

const displayPythonTextWithQuotes = (text: string) => {
  if (typeof text !== 'string') return text;
  if (String(text).includes('\n')) {
    return `"""${text}"""`;
  }
  return `"${text}"`;
};

const displayTsTextWithQuotes = (text: string) => {
  if (typeof text !== 'string') return text;
  if (String(text).includes('\n')) {
    return `\`${text}\``;
  }
  return `'${text}'`;
};

const displayCurlTextWithQuotes = (text: string) => {
  if (typeof text !== 'string') return text;
  return `"${text}"`;
};

export const formatPythonRequest: FormatFn = (
  modelName: string,
  modelUrl: string,
  { maxTokens, temperature, topP }: TextInputParameters<number>,
  apiKey: string,
  messages?: ChatMessage[]
): string =>
  `import requests

url = "${modelUrl}"
headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer ${apiKey}"
}
data = {
    "messages": [${
      Number(messages?.length) > 0
        ? messages
            ?.map(
              (msg) =>
                `
        {
            "role": "${msg.role}",
            "content": ${displayPythonTextWithQuotes(msg.content)}
        }`
            )
            .join(',')
        : ''
    }
    ],
    "model": "${modelName}",
    "max_tokens": ${maxTokens},
    "temperature": ${temperature},
    "top_p": ${topP}
}
  
response = requests.post(url, headers=headers, json=data)
print(response.json())`;

export const formatBasePythonRequest = (
  modelName: string,
  modelUrl: string,
  { maxTokens, temperature, topP }: TextInputParameters<number>,
  apiKey: string,
  prompt: string
) =>
  `import requests

url = "${modelUrl}"
headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer ${apiKey}"
}
data = {
    "prompt": ${displayPythonTextWithQuotes(prompt)},
    "model": "${modelName}",
    "max_tokens": ${maxTokens},
    "temperature": ${temperature},
    "top_p": ${topP}
}
  
response = requests.post(url, headers=headers, json=data)
print(response.json())`;

export const formatTsRequest: FormatFn = (
  modelName: string,
  modelUrl: string,
  { maxTokens, temperature, topP }: TextInputParameters<number>,
  apiKey: string,
  messages?: ChatMessage[]
): string =>
  `const url = '${modelUrl}';

const response = await fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ${apiKey}',
  },
  body: JSON.stringify({
    model: '${modelName}',
    messages: [${
      Number(messages?.length) > 0
        ? messages
            ?.map(
              (msg) =>
                `
      {
        role: '${msg.role}',
        content: ${displayTsTextWithQuotes(msg.content)}
      }`
            )
            .join(',')
        : ''
    }
    ],
    max_tokens: ${maxTokens},
    temperature: ${temperature},
    top_p: ${topP},
    stream: false
  }),
});

const json = await response.json();

const output = json.choices[0].message.content;
console.log(output);`;

export const formatBaseTsRequest = (
  modelName: string,
  modelUrl: string,
  { maxTokens, temperature, topP }: TextInputParameters<number>,
  apiKey: string,
  inputPrompt: string
): string =>
  `const url = '${modelUrl}';

const response = await fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ${apiKey}',
  },
  body: JSON.stringify({
    model: '${modelName}',
    prompt: ${displayTsTextWithQuotes(inputPrompt)},
    max_tokens: ${maxTokens},
    temperature: ${temperature},
    top_p: ${topP},
    stream: false
  }),
});

const json = await response.json();

const output = json.choices[0].text;
console.log(output);`;

export const formatCurlRequest: FormatFn = (
  modelName: string,
  modelUrl: string,
  { maxTokens, temperature, topP }: TextInputParameters<number>,
  apiKey: string,
  messages?: ChatMessage[]
): string => `curl -X POST "${modelUrl}" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer ${apiKey}" \\
    --data-raw '{
        "messages": [${
          Number(messages?.length) > 0
            ? messages
                ?.map(
                  (msg) =>
                    `
            {
                "role": "${msg.role}",
                "content": ${displayCurlTextWithQuotes(msg.content)}
            }`
                )
                .join(',')
            : ''
        }
        ],
        "model": "${modelName}",
        "max_tokens": ${maxTokens},
        "temperature": ${temperature},
        "top_p": ${topP},
        "stream": false
    }'
`;

export const formatBaseCurlRequest = (
  modelName: string,
  modelUrl: string,
  { maxTokens, temperature, topP }: TextInputParameters<number>,
  apiKey: string,
  inputPrompt: string
): string => `curl -X POST "${modelUrl}" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer ${apiKey}" \\
    --data-raw '{
        "prompt": ${displayCurlTextWithQuotes(inputPrompt)},
        "model": "${modelName}",
        "max_tokens": ${maxTokens},
        "temperature": ${temperature},
        "top_p": ${topP},
        "stream": false
    }'
`;

export const formatVisionPythonRequest = (
  modelName: string,
  modelUrl: string,
  { maxTokens, temperature, topP }: TextInputParameters<number>,
  apiKey: string,
  messages?: ChatMessage[]
) => `import json
import base64
import requests
from io import BytesIO
from PIL import Image

def encode_image(img):
    buffered = BytesIO()
    img.save(buffered, format="PNG")
    encoded_string = base64.b64encode(buffered.getvalue()).decode("utf-8")
    return encoded_string

img = Image.open("path_to_your_image")

api = "${modelUrl}"
api_key = "${apiKey}"

headers = {
    "Content-Type": "application/json",
    "Authorization": f"Bearer {api_key}",
}

payload = {
    "messages": [${
      Number(messages?.length) > 0
        ? messages
            ?.map(
              (msg) =>
                `
        {
            "role": "${msg.role}",
            "content": ${displayCurlTextWithQuotes(msg.content)},
            "image": encode_image(img),
            "image_process_mode": "Default",
        }`
            )
            .join(',')
        : ''
    }
    ],
    "model": "${modelName}",
    "max_tokens": ${maxTokens},
    "temperature": ${temperature},
    "top_p": ${topP},
}

with requests.post(api, json=payload, headers=headers, stream=True) as r:
    for chunk in r.iter_content(chunk_size=None):
        chunk = chunk.decode("utf-8")
        for data in chunk.split("\\n\\n"):
            if len(data.strip()) == 0:
                continue
            if "[DONE]" in data[6:]:
                break
            response = json.loads(data[6:])
            content = response["choices"][0]["delta"].get("content", "")
            print(content, end="", flush=True)
`;

export const formatImagePythonRequest = (
  modelName: string,
  modelUrl: string,
  params: ImageInputParameters<number, boolean>,
  apiKey: string
) => `import requests

url = "${modelUrl}"
headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer ${apiKey}"
}
data = {
    "model_name": "${modelName}",${Object.entries(params)
  .map(
    ([key, value]: [key: string, value: string]) =>
      `
    "${snakeCase(key)}": ${displayPythonTextWithQuotes(value)}`
  )
  .join(',')},
    "backend": "auto"
}
  
response = requests.post(url, headers=headers, json=data)
print(response.json())
`;

export const formatImageTsRequest = (
  modelName: string,
  modelUrl: string,
  params: ImageInputParameters<number, boolean>,
  apiKey: string
) => `const url = '${modelUrl}';

const response = await fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ${apiKey}',
  },
  body: JSON.stringify({
    'model_name': '${modelName}',${Object.entries(params)
  .map(
    ([key, value]: [key: string, value: string]) =>
      `
    '${snakeCase(key)}': ${displayTsTextWithQuotes(value)}`
  )
  .join(',')},
    'backend': 'auto'
  }),
});

const json = await response.json();

const output = json.images[0];
console.log(output);`;

export const formatImageCurlRequest = (
  modelName: string,
  modelUrl: string,
  params: ImageInputParameters<number, boolean>,
  apiKey: string
) => `curl -X POST "${modelUrl}" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer ${apiKey}" \\
    -d '{
        "model_name": "${modelName}",${Object.entries(params)
  .map(
    ([key, value]: [key: string, value: string]) =>
      `
        "${snakeCase(key)}": ${displayCurlTextWithQuotes(value)}`
  )
  .join(',')},
        "backend": "auto"
    }'`;

export const formatAudioPythonRequest = (
  modelName: string,
  modelUrl: string,
  params: AudioInputParameters<number>,
  apiKey: string,
  text: string
) => `import requests

url = "${modelUrl}"
headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer ${apiKey}"
}
data = {
    "text": ${displayPythonTextWithQuotes(text)},${Object.entries(params)
  .map(
    ([key, value]: [key: string, value: string]) =>
      `
    "${snakeCase(key)}": ${displayPythonTextWithQuotes(value)}`
  )
  .join(',')}
}
  
response = requests.post(url, headers=headers, json=data)
print(response.json())
`;

export const formatAudioTsRequest = (
  modelName: string,
  modelUrl: string,
  params: AudioInputParameters<number>,
  apiKey: string,
  text: string
) => `const url = '${modelUrl}';

const response = await fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ${apiKey}',
  },
  body: JSON.stringify({
    'text': ${displayTsTextWithQuotes(text)},${Object.entries(params)
  .map(
    ([key, value]: [key: string, value: string]) =>
      `
    '${snakeCase(key)}': ${displayTsTextWithQuotes(value)}`
  )
  .join(',')}
  }),
});

const json = await response.json();

const output = json.images[0];
console.log(output);`;

export const formatAudioCurlRequest = (
  modelName: string,
  modelUrl: string,
  params: AudioInputParameters<number>,
  apiKey: string,
  text: string
) => `curl -X POST "${modelUrl}" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer ${apiKey}" \\
    -d '{
        "text": "${text}",${Object.entries(params)
  .map(
    ([key, value]: [key: string, value: string]) =>
      `
        "${snakeCase(key)}": ${displayCurlTextWithQuotes(value)}`
  )
  .join(',')}
    }'`;

export const createModelPath = ({
  slug,
  mode,
  lang,
}: {
  slug: string;
  mode?: string;
  lang?: string;
}) => {
  let path = `/models/${slug}`;
  if (mode) {
    path += `/${mode}`;
    if (lang) {
      path += `/${lang}`;
    }
  }
  return path;
};

export const convertKeysToSnake = (obj: any) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({ ...acc, [snakeCase(key)]: value }),
    {}
  );

export const mapNumberFormatToTooltip: { [key: string]: string } = {
  bf16: 'It offers the most precision and best performance at a slightly higher cost.',
  fp8: `It's fast and perfect for applications where speed matters more than precision.`,
};

export const displayMaxTokens = (maxMax: number) => {
  const kAmount = Math.floor(maxMax / 1000);
  if (kAmount > 1) {
    return `${kAmount}k`;
  }
  return maxMax;
};

export const badgeClassName = 'text-xs py-0 px-1.5 text-theme-neutral-600 border-theme-neutral-400 bg-transparent rounded';
import { useDispatch, useSelector } from 'react-redux';
import UserDropdown from '../UserDropdown';
import Button from '../common/Button';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FiChevronLeft, FiDatabase } from 'react-icons/fi';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { HeaderLink } from '.';
import MobileNavBar from '../MobileNavBar';
import HyperLink from '../common/HyperLink';
import { useMatches } from 'react-router-dom';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import AuthButton from '../AuthButton';
import twClassnames from '../../utils/classnames';
import { AppDispatch } from '../../store';
import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import { getBalance } from '../../slices/usage';
import useUser from '../../hooks/useUser';
// import { useState } from 'react';
// import Input from './common/Input';
// import { TbSearch } from 'react-icons/tb';

const Header = ({ className = '' }) => {
  // const [searchInput, setSearchInput] = useState('');
  const balance = useSelector(getBalance);
  const { user } = useUser();
  const matches = useMatches();
  const dispatch = useDispatch<AppDispatch>();
  const goBack = (matches[matches.length - 1]?.handle as any)?.goBack;
  const billingEnabled = useFeatureFlagEnabled('billing');
  const posthog = usePostHog();
  return (
    <header
      className={twClassnames(
        'flex items-center justify-between py-5 px-8 sticky top-0 gap-2 z-10 bg-white md:h-[60px] md:min-h-[60px]',
        className
      )}
    >
      {/* <Input
        value={searchInput}
        onChangeText={setSearchInput}
        startIcon={<TbSearch size={20} />}
        placeholder="Search your models"
        containerClassName="flex-1 max-w-md"
      /> */}
      <MobileNavBar className="-ml-2" />
      {goBack && (
        <HyperLink
          to={goBack}
          className="flex items-center text-theme-neutral-600 hidden md:flex"
        >
          <FiChevronLeft size={20} className="mr-1" /> Go back
        </HyperLink>
      )}
      <div className="flex-1 flex items-center justify-end gap-2">
        <HeaderLink
          href="https://docs.hyperbolic.xyz/docs/getting-started"
          Icon={IoDocumentTextOutline}
          title="Docs"
          className="hidden md:flex"
        />
        <HeaderLink
          href="https://docs.hyperbolic.xyz/docs/hyperbolic-pricing"
          Icon={FiDatabase}
          title="Pricing"
          className="hidden md:flex"
        />
        {user ? (
          <>
            {billingEnabled && (
              <div className="p-1 mx-3 border border-theme-neutral-400 rounded-[15px] hidden md:flex items-center text-theme-primary-600 text-sm">
                <AiOutlineDollarCircle size={22} className="mr-1" />
                <div
                  className={`font-semibold transition-opacity ${
                    balance == null ? 'opacity-10 animate-pulse' : 'opacity-100'
                  }`}
                >
                  {balance != null ? (balance / 100).toFixed(2) : '...'}
                </div>
                <Button
                  className="ml-3 py-1 px-3 rounded-xl text-sm"
                  onClick={() => {
                    posthog?.capture('Deposit Button Clicked');
                    dispatch(
                      openModal({
                        name: ModalName.AddFunds,
                        props: {},
                        styles: {
                          content: {
                            maxWidth: '650px',
                          },
                        },
                      })
                    );
                  }}
                >
                  Deposit
                </Button>
              </div>
            )}
            <UserDropdown />
          </>
        ) : (
          <AuthButton className="text-sm" />
        )}
      </div>
    </header>
  );
};

export default Header;

import { useLocation, useParams } from 'react-router-dom';
import { supportSections } from '../../utils/support';
import HyperLink from '../common/HyperLink';
import ExpandableQuestion from './ExpandableQuestion';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const FAQSection = () => {
  const { sectionId } = useParams();
  const section = supportSections.find((sec) => sec.id === sectionId);
  const { state } = useLocation();
  const marketplaceEnabled = useFeatureFlagEnabled('marketplace_v2');
  const questions = section?.questions.filter(
    (q) => !(q.hideAlpha && marketplaceEnabled)
  );
  return (
    <>
      <div className="text-sm flex items-center gap-1 -mt-10">
        <HyperLink
          to="/support"
          className="text-theme-neutral-600 underline hover:text-theme-neutral-700"
        >
          Help Center
        </HyperLink>
        &gt;
        <span className="text-theme-primary-600">{section?.title}</span>
      </div>
      <div className="w-full h-[1px] bg-theme-neutral-200" />
      <div className="p-9 rounded-lg bg-theme-neutral-50 flex flex-col gap-8">
        <div>
          <div className="text-lg text-black font-semibold mb-2">
            {section?.title} FAQs
          </div>
          <div className="text-sm">{section?.description}</div>
        </div>
        <div className="flex flex-col gap-5">
          {questions?.map((q, i) => (
            <ExpandableQuestion
              key={q.question}
              question={q.question}
              answer={q.answer}
              defaultExpanded={state?.initialQuestion === i}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQSection;

import { useDispatch, useSelector } from 'react-redux';
import RentedInstanceCard from '../components/Compute/RentedInstanceCard';
import SuppliedInstanceCard from '../components/Compute/SuppliedInstanceCard';
import { AppDispatch } from '../store';
import {
  fetchRentedInstances,
  fetchSuppliedInstances,
  getStatus,
  getFormattedRentedInstances,
  getFormattedSuppliedInstances,
  getRentedInstancesInitialLoading,
} from '../slices/instances';
import { useEffect, useRef } from 'react';
import LoadingSpinner from '../components/common/LoadingSpinner';
import useInterval from '../hooks/useInterval';
import { InstanceRental, ResponseStatus } from '../utils/types';
import useUser from '../hooks/useUser';
import useInstanceFilters, {
  PriceFilterOption,
  priceOptions,
  quantityOptions,
} from '../hooks/useInstanceFilters';
import FilterDropdown from '../components/Compute/FilterDropdown';
import DurationDropdown from '../components/Compute/DurationDropdown';
import { fetchInstanceHistory, getInstanceHistory, getInstanceHistoryInitialLoading } from '../slices/usage';
import InstanceHistoryCard from '../components/Compute/InstanceHistoryCard';
import SearchIcon from '../components/common/icons/SearchIcon';
import HyperLink from '../components/common/HyperLink';
import { usePostHog } from 'posthog-js/react';

const MyInstances = () => {
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const rentedInstances = useSelector(getFormattedRentedInstances);
  const suppliedInstances = useSelector(getFormattedSuppliedInstances);
  const rentedInstancesInitialLoading = useSelector(
    getRentedInstancesInitialLoading
  );
  const instanceHistoryInitialLoading = useSelector(getInstanceHistoryInitialLoading);
  const status = useSelector(getStatus);
  const loading = status == ResponseStatus.Loading;
  const instanceHistory = useSelector(getInstanceHistory);

  const {
    locationOptions,
    gpuOptions,
    filteredStorageOptions,
    quantityFilter,
    gpuFilter,
    storageFilter,
    locationFilter,
    priceFilter,
    sortedInstances: sortedRentedInstances,
    handleFilterChange,
    daysFilter,
  } = useInstanceFilters(
    rentedInstances,
    (rental: InstanceRental) => rental.instance,
    false
  );

  const { user, authToken } = useUser();
  const authTokenRef = useRef('');

  useEffect(() => {
    if (authToken && authToken !== authTokenRef.current) {
      authTokenRef.current = authToken;
      dispatch(fetchRentedInstances());
      dispatch(fetchSuppliedInstances());
      dispatch(fetchInstanceHistory());
    }
  }, [authToken, dispatch]);

  useInterval(() => {
    if (authToken) {
      dispatch(fetchRentedInstances());
      dispatch(fetchSuppliedInstances());
      dispatch(fetchInstanceHistory());
    }
  }, 5000);

  // todo: switch to toggle to supplied instances as well, or is that on the supply page?

  return (
    <div className="flex flex-col w-full gap-6">
      <div className="flex items-center">
        <div className="text-lg font-semibold text-theme-neutral-700">
          Active
        </div>
        {loading && (
          <div className="ml-4">
            <LoadingSpinner className="w-4 h-4 mr-2" />
          </div>
        )}
      </div>
      {/* TODO: Move filters to a separate component since we are using this element structure everywhere */}
      {sortedRentedInstances.length > 0 && (
        <div className="flex rounded-lg bg-theme-primary-50">
          <div className="hidden sm:flex">
            <FilterDropdown
              options={quantityOptions}
              filter={quantityFilter}
              onChange={(value) =>
                handleFilterChange({ id: 'quantityFilter', value })
              }
              buttonClassName="w-32"
              overlayClassName="w-32"
              placeholder="Quantity"
            />
          </div>
          <div className="flex-1">
            <FilterDropdown
              dropdownClassName="inline-block"
              overlayClassName="w-48"
              options={gpuOptions}
              filter={gpuFilter}
              onChange={(value) =>
                handleFilterChange({ id: 'gpuFilter', value })
              }
              placeholder="GPU Type"
            />
          </div>
          <div className="hidden sm:flex flex-1">
            <FilterDropdown
              dropdownClassName="inline-block"
              overlayClassName="w-44"
              options={filteredStorageOptions}
              filter={storageFilter}
              onChange={(value) =>
                handleFilterChange({ id: 'storageFilter', value })
              }
              placeholder="Storage"
            />
          </div>
          <div className="hidden lg:flex flex-1">
            <FilterDropdown
              dropdownClassName="inline-block"
              placeholder="Location"
              options={locationOptions}
              filter={locationFilter}
              onChange={(value) =>
                handleFilterChange({ id: 'locationFilter', value })
              }
              overlayClassName="w-44"
            />
          </div>
          <div className="hidden lg:flex flex-1">
            <DurationDropdown
              range={daysFilter}
              onChangeRange={(range) =>
                handleFilterChange({ id: 'daysFilter', value: range })
              }
            />
          </div>
          <div className="flex-1">
            <FilterDropdown
              dropdownClassName="inline-block"
              placeholder="Price"
              options={priceOptions}
              filter={priceFilter}
              onChange={(value) =>
                handleFilterChange({
                  id: 'priceFilter',
                  value: value as PriceFilterOption,
                })
              }
              defaultLabel="Show all"
              overlayClassName="w-48"
            />
          </div>
          <div className="w-6" />
        </div>
      )}
      {!sortedRentedInstances?.length &&
        !!user &&
        !rentedInstancesInitialLoading && (
          <div className="flex flex-col gap-4">
            <div>You currently have no active instances.</div>
            <div className="flex">
              <HyperLink
                to="/compute/rent"
                variant="outline"
                className="flex items-center text-base py-2"
                onClick={() => posthog?.capture('Find GPUs Clicked')}
              >
                <SearchIcon className="mr-3" />
                Find GPUs
              </HyperLink>
            </div>
          </div>
        )}
      {rentedInstancesInitialLoading && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
            </div>
          </div>
        </div>
      )}
      {(sortedRentedInstances as InstanceRental[]).map((i) => (
        <RentedInstanceCard key={i.id} rental={i} />
      ))}
      {instanceHistory?.length > 0 && (
        <div className="text-lg font-semibold text-theme-neutral-700">
          History
        </div>
      )}
      {/* TODO: add filters */}
      {instanceHistoryInitialLoading && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
            </div>
          </div>
        </div>
      )}
      {instanceHistory.map((i) => (
        <InstanceHistoryCard
          key={`${i.instance_name}-${i.started_at}`}
          instanceHistory={i}
        />
      ))}
      {/* TODO: add pagination */}
      {!!suppliedInstances.length && (
        <div className="flex flex-col gap-4">
          <div className="flex items-center text-lg font-semibold text-theme-neutral-700">
            Supplied Instances
            {loading && (
              <div className="ml-4">
                <LoadingSpinner className="w-4 h-4 mr-2" />
              </div>
            )}
          </div>
          {suppliedInstances.map((instance) => (
            <SuppliedInstanceCard key={instance.id} instance={instance} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MyInstances;

import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import twClassnames from '../../../utils/classnames';
import './Message.css';
import CopyButton from '../CopyButton';
import HyperLink from '../HyperLink';
import { usePostHog } from 'posthog-js/react';

const Message = ({
  id,
  text,
  image,
  fromOther = false,
  apiPath = '',
}: {
  id: string;
  text: string;
  image?: string;
  fromOther?: boolean;
  apiPath?: string;
}) => {
  const posthog = usePostHog();
  return (
    <div className={twClassnames('flex', { 'justify-end': !fromOther })}>
      <div className="flex flex-col items-end">
        {image && (
          <img
            src={`data:image/png;base64,${image}`}
            className="w-40 rounded-xl"
          />
        )}
        <div
          className={twClassnames(
            'px-4 py-3 bg-theme-primary-600 rounded-[24px] text-white my-3 flex flex-col gap-2 text-sm',
            {
              'bg-transparent text-black rounded-bl-none p-0 mr-8 md:mr-28':
                fromOther,
              'ml-8 md:ml-28 rounded-br-none': !fromOther,
            }
          )}
        >
          <Markdown
            className={twClassnames(
              'message-markdown flex flex-col gap-3 leading-6',
              {
                'message-user': !fromOther,
              }
            )}
            components={{
              code(props) {
                const { children, className, ...rest } = props;
                const newLines = children?.toString().split('\n')?.length || 0;
                const inline = newLines <= 1;
                const match = /language-(\w+)/.exec(className || '');
                return !inline ? (
                  <SyntaxHighlighter
                    {...(rest as any)}
                    PreTag="div"
                    children={String(children).replace(/\n$/, '')}
                    language={match?.[1] || 'python'}
                    style={oneLight}
                    className="text-sm rounded-lg border"
                    customStyle={{ padding: '30px', overflow: 'unset' }}
                    wrapLongLines
                  />
                ) : (
                  <code
                    className={twClassnames('inline-code', className)}
                    {...rest}
                  >
                    {children}
                  </code>
                );
              },
            }}
          >
            {text}
          </Markdown>
          {fromOther && id !== 'ai-response' && (
            <div className="flex gap-2">
              <CopyButton
                id={`message-${id}`}
                copyText={text}
                className="text-theme-neutral-700 hover:text-theme-neutral-500"
                size={16}
              />
              <HyperLink
                variant="ghost"
                to={apiPath}
                onClick={() =>
                  posthog?.capture('View API Clicked', { modelId: id })
                }
                className="text-xs px-3 py-1.5"
              >
                View API
              </HyperLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;

import { useDropzone } from 'react-dropzone';
import Dropdown, {
  DropdownButton,
  DropdownOverlay,
  DropdownMenuItem,
  DropdownMenu,
} from '../common/Dropdown';
import { BsPaperclip } from 'react-icons/bs';
import { GrDocumentUpload } from 'react-icons/gr';

const UploadDropdown = ({ onDrop }: { onDrop: any }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'image/*': ['.png', '.jpeg', '.jpg'] },
  });
  return (
    <Dropdown>
      <DropdownButton
        className="text-sm text-theme-neutral-700 hover:text-theme-primary-600"
        variant="link"
        displayPosition={false}
      >
        <BsPaperclip size={20} />
      </DropdownButton>
      <DropdownOverlay className="w-48 left-0 bottom-0 mb-7">
        <DropdownMenu>
          <DropdownMenuItem
            className="text-sm"
            childClassName="flex items-center"
            hideCheck
            {...getRootProps()}
          >
            <GrDocumentUpload className="mr-2" />
            Upload an image
            <input {...getInputProps()} />
          </DropdownMenuItem>
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default UploadDropdown;

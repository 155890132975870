import { Toaster } from 'react-hot-toast';
import ModalPortal from './ModalPortal';
import Header from './Header';
import SideNavBar from './SideNavBar';
import twClassnames from '../utils/classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import backgroundImage from '../assets/login-background.png';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from '../slices/auth';
import useVerifyUser from '../hooks/useOnboardUser';

const Base = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const location = useLocation();
  const showBackgroundImage = !isAuthenticated && location.pathname === '/';

  useVerifyUser();
  
  return (
    <>
      <div className="flex h-screen">
        <SideNavBar />
        <main
          className={twClassnames(
            'flex flex-col flex-1 md:h-screen overflow-y-auto',
            { 'bg-no-repeat bg-cover bg-left-bottom': showBackgroundImage }
          )}
          {...(showBackgroundImage && {
            style: { backgroundImage: `url(${backgroundImage})` },
          })}
        >
          <Header className={showBackgroundImage ? 'bg-transparent' : ''} />
          <Outlet />
        </main>
      </div>
      <ModalPortal />
      <Toaster
        toastOptions={{
          loading: {
            className: '!text-white !text-sm !bg-theme-primary-600 !px-4 !py-1',
          },
          success: {
            className: '!text-white !text-sm !bg-theme-success-500 !px-4 !py-1',
            icon: <FaCircleCheck />,
          },
          error: {
            className: '!text-white !text-sm !bg-theme-danger-500 !px-4 !py-1',
            icon: <FaCircleXmark />,
          },
          position: 'top-right',
        }}
        containerClassName="mt-8"
      />
    </>
  );
};

export default Base;

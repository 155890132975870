import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles } from '../../utils/modals';
import { FiPlus } from 'react-icons/fi';
import useUser from '../../hooks/useUser';
import { useDispatch, useSelector } from 'react-redux';
import gpuNeutral from '../../assets/gpu-neutral-100.png';
import Button from '../common/Button';
import HyperLink from '../common/HyperLink';
import {
  deleteMachineRequest,
  getMachineRequests,
} from '../../slices/machines';
import { AppDispatch } from '../../store';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import AuthButton from '../AuthButton';

const MarketplaceWaitlist = () => {
  const machineRequests = useSelector(getMachineRequests);
  const { user } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const isMarketplaceV2Enabled = useFeatureFlagEnabled('marketplace_v2');
  const machineRequest = machineRequests.find(
    (mac) => mac?.email === user?.email && mac.mode !== 'notify'
  );
  const isSignedUp = user?.email && !!machineRequest;
  return (
    <div className="flex-1 justify-center items-center px-6 md:px-16 w-full flex flex-col gap-8">
      <div className="text-center text-lg text-theme-neutral-700">
        {isSignedUp ? (
          isMarketplaceV2Enabled ? (
            <div className="flex flex-col gap-4">
              <div className="font-semibold text-2xl">
                🎊 Congratulations! You're in! 🎊
              </div>
              <div className="font-light">
                Please remember, this is an alpha release and not perfect.
                <br />
                Your feedback is crucial to help us improve.
                <br />
              </div>
              <div className="font-light">
                Join our&nbsp;
                <HyperLink
                  href="https://discord.gg/taS7bPtF"
                  className="text-lg font-medium"
                >
                  discord channel
                </HyperLink>
                &nbsp;to stay in touch
              </div>
            </div>
          ) : (
            <div className="font-light">
              Thank you for joining the waitlist! We will review your
              application and get back to you shortly.
              <br />
              While waiting for approval, feel free to explore our&nbsp;
              <HyperLink to="/models" className="text-lg">
                AI inference playground
              </HyperLink>
              !
            </div>
          )
        ) : (
          <div className="font-light">
            Hi! We are currently testing our GPU marketplace product.
            <br />
            Sign up for the waitlist if you are interested in renting GPUs from
            us and we will be in touch shortly. Thank you!
          </div>
        )}
      </div>
      {isSignedUp ? (
        isMarketplaceV2Enabled ? (
          <Button
            className="text-base"
            onClick={() => dispatch(deleteMachineRequest(machineRequest.id))}
          >
            Start Renting
          </Button>
        ) : (
          <div className="px-5 py-2 border rounded-lg border-theme-primary-200 bg-theme-primary-50">
            Application status:&nbsp;
            <span className="text-theme-primary-600 font-medium">Pending</span>
          </div>
        )
      ) : user ? (
        <Button
          variant="outline"
          className="flex items-center gap-1 text-base"
          onClick={() =>
            dispatch(
              openModal({
                name: ModalName.GPURequestModal,
                styles: isMobile
                  ? fullScreenStyles
                  : { content: { maxWidth: '700px' } },
              })
            )
          }
        >
          <FiPlus size={20} /> Join Waitlist
        </Button>
      ) : (
        <AuthButton className="text-base" />
      )}
      <img
        src={gpuNeutral}
        style={{ height: 360 }}
        className="object-contain"
      />
    </div>
  );
};

export default MarketplaceWaitlist;

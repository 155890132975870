import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import {
  fetchMarketplaceInstances,
  getFormattedMarketplaceInstances,
  getMarketplaceInitialLoading,
} from '../slices/instances';
import { AppDispatch } from '../store';
import FilterDropdown from '../components/Compute/FilterDropdown';
import { Instance } from '../utils/types';
import DurationDropdown from '../components/Compute/DurationDropdown/DurationDropdown';
import MarketplaceInstanceCard from '../components/Compute/MarketplaceInstanceCard';
import Button from '../components/common/Button';
import useUser from '../hooks/useUser';
import useInterval from '../hooks/useInterval';
import useInstanceFilters, {
  priceOptions,
  quantityOptions,
} from '../hooks/useInstanceFilters';
import { openModal } from '../slices/modals';
import { ModalName } from '../components/modals';
import { usePostHog } from 'posthog-js/react';
import Closeable from '../components/common/Closeable';

const MarketplaceInstances = () => {
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const instances = useSelector(getFormattedMarketplaceInstances);
  const marketplaceInitialLoading = useSelector(getMarketplaceInitialLoading);
  const {
    locationOptions,
    gpuOptions,
    filteredStorageOptions,
    quantityFilter,
    gpuFilter,
    storageFilter,
    locationFilter,
    priceFilter,
    sortedInstances,
    handleFilterChange,
    daysFilter,
  } = useInstanceFilters(instances);
  const { authToken } = useUser();
  const authTokenRef = useRef('');

  useEffect(() => {
    if (authToken && authToken !== authTokenRef.current) {
      authTokenRef.current = authToken;
      dispatch(fetchMarketplaceInstances());
    }
  }, [authToken, dispatch]);

  useInterval(() => {
    if (authToken) {
      dispatch(fetchMarketplaceInstances());
    }
  }, 5000);

  const handleFilterDropdownChange = (filterId: string) => (value: any) => {
    posthog?.capture('Marketplace Filter Changed', {
      filterId,
      filterSelected: value,
    });
    handleFilterChange({ id: filterId, value });
  };

  // todo: maybe find a better way to handle union types
  const unavailable = !sortedInstances?.filter(
    (ir: any) => !ir.reserved && !ir.instance?.reserved
  ).length && !marketplaceInitialLoading;

  return (
    <div className="flex flex-col w-full gap-6">
      <div className="flex rounded-lg bg-theme-primary-50">
        <div className="hidden sm:flex">
          <FilterDropdown
            options={quantityOptions}
            filter={quantityFilter}
            onChange={handleFilterDropdownChange('quantityFilter')}
            buttonClassName="w-32"
            overlayClassName="w-32"
            placeholder="Quantity"
          />
        </div>
        {/* TODO: show all possible gpu options */}
        <div className="flex-1">
          <FilterDropdown
            dropdownClassName="inline-block"
            overlayClassName="w-48"
            options={gpuOptions}
            filter={gpuFilter}
            onChange={handleFilterDropdownChange('gpuFilter')}
            placeholder="GPU Type"
          />
        </div>
        <div className="hidden sm:flex flex-1">
          <FilterDropdown
            dropdownClassName="inline-block"
            overlayClassName="w-44"
            options={filteredStorageOptions}
            filter={storageFilter}
            onChange={handleFilterDropdownChange('storageFilter')}
            placeholder="Storage"
          />
        </div>
        <div className="hidden lg:flex flex-1">
          <FilterDropdown
            dropdownClassName="inline-block"
            placeholder="Location"
            options={locationOptions}
            filter={locationFilter}
            onChange={handleFilterDropdownChange('locationFilter')}
            overlayClassName="w-44"
          />
        </div>
        <div className="hidden lg:flex flex-1">
          <DurationDropdown
            range={daysFilter}
            onChangeRange={handleFilterDropdownChange('daysFilter')}
          />
        </div>
        <div className="flex-1">
          <FilterDropdown
            dropdownClassName="inline-block"
            placeholder="Price"
            options={priceOptions}
            filter={priceFilter}
            onChange={handleFilterDropdownChange('priceFilter')}
            defaultLabel="Show all"
            overlayClassName="w-48"
          />
        </div>
        <div className="w-6" />
      </div>
      {!!unavailable && (
        <Closeable className="bg-theme-primary-50 py-3 px-5 w-full rounded-lg text-theme-neutral-600 text-sm">
          {/* TODO: detect filters and add them to notify */}
          <div className="flex flex-wrap items-center gap-1">
            <div>
              We are sorry that we don't have any available GPUs
              {instances?.length > 0 && ` that match your filters`}.
            </div>
            <Button
              variant="link"
              onClick={() => dispatch(openModal({ name: ModalName.NotifyMe }))}
              className="text-sm"
            >
              Notify me when available.
            </Button>
          </div>
        </Closeable>
      )}
      <div className="flex flex-col gap-4">
        {/* {!sortedInstances.length && !!user && (
          <Card noHover className="select-none">
            <div>
              <span>No available instances match your filters</span>
              <Button
                onClick={() => dispatch(fetchMarketplaceInstances())}
                className="ml-4"
                isLoading={status === ResponseStatus.Loading}
              >
                Retry
              </Button>
            </div>
          </Card>
        )} */}
        {/* {loading && (
          <div className="ml-4">
            <LoadingSpinner className="w-4 h-4 mr-2" />
          </div>
        )} */}
        {marketplaceInitialLoading ? (
          <div className="mb-6">
            <div className="animate-pulse">
              <div className="flex flex-col gap-4">
                <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
                <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
                <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              </div>
            </div>
          </div>
        ) : (
          (sortedInstances as Instance[]).map((instance) => (
            <MarketplaceInstanceCard key={instance.id} instance={instance} />
          ))
        )}
      </div>
    </div>
  );
};

export default MarketplaceInstances;

const Team = () => {
  return (
    <div className="flex flex-col w-full px-10">
      <div className="text-xl font-semibold mb-8 text-theme-neutral-700">
        Team
      </div>
      <div className="flex items-center text-theme-neutral-700 mb-6">
        <div>Team info coming soon!</div>
      </div>
    </div>
  );
};

export default Team;

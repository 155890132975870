import { useDispatch, useSelector } from 'react-redux';
import { usePostHog } from 'posthog-js/react';
import { Machine } from '../../utils/types';
import Button from '../common/Button';
import { getUser } from '../../slices/auth';
import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import { AppDispatch } from '../../store';
import twClassnames from '../../utils/classnames';
import AuthButton from '../AuthButton';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles, mobileStyles } from '../../utils/modals';

const MachineSubmitButton = ({
  className = '',
  machine,
  onDelete,
}: {
  className?: string;
  machine: Machine;
  onDelete?: () => void;
}) => {
  const posthog = usePostHog();
  const user = useSelector(getUser);
  const dispatch = useDispatch<AppDispatch>();
  if (!user) {
    return (
      <AuthButton
        className={className}
        from={`/compute/machines/${machine.id}`}
      />
    );
  }
  if (machine.owner === user?.uid) {
    return (
      <Button
        variant="danger-outline"
        className={className}
        onClick={(e) => {
          e.preventDefault();
          if (machine?.id) {
            dispatch(
              openModal({
                name: ModalName.ConfirmDeleteGPUModal,
                props: { machineId: machine?.id, onDelete },
                styles: isMobile
                  ? mobileStyles
                  : {
                      content: {
                        maxWidth: 480,
                      },
                    },
              })
            );
          }
        }}
      >
        Delete
      </Button>
    );
  }
  return (
    <Button
      className={twClassnames('text-sm', className)}
      onClick={(e) => {
        e.preventDefault();
        posthog?.capture('Compute Contact Seller Clicked', {
          machine_id: machine.id,
        });
        dispatch(
          openModal({
            name: ModalName.ContactSupplierModal,
            props: {
              supplier: {
                name: machine.name,
                email: machine.email,
                owner: machine.owner,
              },
              machineId: machine.id,
            },
            ...(isMobile && { styles: fullScreenStyles }),
          })
        );
      }}
    >
      Rent
    </Button>
  );
};

export default MachineSubmitButton;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../store';
import { useEffect } from 'react';
import { fetchSingleMachine, getMachine } from '../slices/machines';
import { gpuTypes, interconnectNetworks } from '../utils/instances';
import MachineSubmitButton from '../components/Compute/MachineSubmitButton';
import { format } from 'date-fns';
import { DAY, HOUR, MINUTE } from '../utils/constants';
import { pluralize } from '../utils/strings';
import twClassnames from '../utils/classnames';
import { formatCurrency } from '../utils/value';

const MachineFieldDetail = ({
  label,
  value,
  highlighted = false,
}: {
  label: string;
  value: string | number;
  highlighted?: boolean;
}) => (
  <div className="flex">
    <div className="font-bold mr-2">{label}:</div>
    <div
      className={twClassnames({
        'font-semibold text-theme-primary-600': highlighted,
      })}
    >
      {value}
    </div>
  </div>
);

const timeAgo = (created: number | undefined) => {
  if (!created) return;
  const timeDiff = Date.now() - created;
  const dayDiff = Math.floor(timeDiff / DAY);
  if (dayDiff > 0) {
    return `${pluralize(dayDiff, 'day')} ago`;
  }
  const hourDiff = Math.floor(timeDiff / HOUR);
  if (hourDiff > 0) {
    return `${pluralize(hourDiff, 'hour')} ago`;
  }
  const minDiff = Math.floor(timeDiff / MINUTE);
  if (minDiff > 0) {
    return `${pluralize(minDiff, 'min')} ago`;
  }
  return 'recently';
};

const MachineDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { machineId } = useParams();
  const machine = useSelector((state: RootState) =>
    getMachine(state, machineId)
  );
  useEffect(() => {
    if (machineId) {
      dispatch(fetchSingleMachine(machineId));
    }
  }, [dispatch, machineId]);
  const gpuModel = gpuTypes.find((gpu) => gpu.value === machine?.gpuModel);
  const interconnectNetwork = interconnectNetworks.find(
    (net) => net.value === machine?.interconnectNetwork
  );
  return (
    <div className="flex flex-col w-full px-10 gap-5">
      <div className="flex items-center gap-1 text-theme-neutral-700">
        <div className="text-lg font-semibold">Machine Details</div>
        &bull;<div>Posted {timeAgo(machine?.created)}</div>
      </div>
      {machine && (
        <div className="flex flex-col gap-4 border border-theme-primary-100 bg-theme-primary-50 rounded-lg px-8 py-6">
          <MachineFieldDetail label="Posted By" value={machine.name} />
          <MachineFieldDetail
            label="GPU model"
            value={gpuModel?.label || machine.gpuModel}
          />
          <MachineFieldDetail label="Number of GPUs" value={machine.gpuCount} />
          <MachineFieldDetail
            label="Interconnect network"
            value={interconnectNetwork?.label || machine.interconnectNetwork}
          />
          <MachineFieldDetail label="Node CPU RAM" value={`${machine.ram}GB`} />
          {machine.cpuCores && (
            <MachineFieldDetail
              label="CPU Cores per node"
              value={machine.cpuCores}
            />
          )}
          <MachineFieldDetail
            label="Storage capacity per node"
            value={`${machine.storageCapacity}GB`}
          />
          <MachineFieldDetail
            label="Cluster interface"
            value={machine.clusterInterface}
          />
          {machine.networkBandwidth && (
            <MachineFieldDetail
              label="Network bandwidth"
              value={machine.networkBandwidth}
            />
          )}
          <MachineFieldDetail
            label="Operating System (OS)"
            value={machine.operatingSystem}
          />
          {machine.additionalDetails && (
            <MachineFieldDetail
              label="Additional Details"
              value={machine.additionalDetails}
            />
          )}
          <MachineFieldDetail label="Location" value={machine.location} />
          {machine.startDate && machine.endDate && (
            <MachineFieldDetail
              label="Availability"
              value={`${format(machine.startDate, 'M/dd/yyyy')} - ${format(
                machine.endDate,
                'M/dd/yyyy'
              )}`}
            />
          )}
          <MachineFieldDetail
            label="Price"
            value={`${formatCurrency(machine.price)} / GPU / hr`}
            highlighted
          />
          <div>
            <MachineSubmitButton
              machine={machine}
              className="px-12"
              onDelete={() => navigate('/compute')}
            />
          </div>
        </div>
      )}
      <div className="text-sm">
        We are working on a fully automatic version of the GPU Network and will
        launch soon. Stay tuned!
      </div>
    </div>
  );
};

export default MachineDetails;

import twClassnames from '../../utils/classnames';
import Card from '../common/Card';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { format } from 'date-fns';
import { formatCurrency } from '../../utils/value';
import { ReactNode, useState } from 'react';
import { InstanceStatus } from '../../utils/types';
import Badge from '../common/Badge';
import LoadingLine from '../common/LoadingLine';
import { displayPricePeriod } from '../../utils/instances';

const defaultStatusConfig = {
  pillLabel: '',
  pillStyle: '',
  bubbleStyle: '',
  stripeStyle: '',
  backgroundStyle: '',
};

const instanceStatusConfig = {
  [InstanceStatus.unknown]: {
    pillLabel: 'Offline',
    pillStyle:
      'bg-white border border-theme-neutral-200 text-theme-neutral-600',
    bubbleStyle: 'bg-theme-neutral-100 text-theme-neutral-600',
    stripeStyle: 'bg-theme-neutral-500',
    backgroundStyle: 'bg-theme-neutral-50',
  },
  [InstanceStatus.online]: {
    pillLabel: 'Ready to Connect',
    pillStyle:
      'bg-white border border-theme-success-700 text-theme-success-700',
    bubbleStyle: 'bg-theme-neutral-100 text-theme-neutral-600',
    stripeStyle: 'bg-theme-success-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.offline]: {
    pillLabel: 'Ended',
    pillStyle:
      'bg-white border border-theme-neutral-200 text-theme-neutral-600',
    bubbleStyle: 'bg-theme-neutral-100 text-theme-neutral-600',
    stripeStyle: 'bg-theme-neutral-500',
    backgroundStyle: 'bg-theme-neutral-50',
  },
  [InstanceStatus.starting]: {
    pillLabel: 'Starting...',
    pillStyle: 'bg-theme-primary-100 text-theme-primary-600',
    bubbleStyle: '',
    stripeStyle: 'bg-theme-primary-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.node_ready]: {
    pillLabel: 'Starting...',
    pillStyle: 'bg-theme-primary-100 text-theme-primary-600',
    bubbleStyle: '',
    stripeStyle: 'bg-theme-primary-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.stopping]: {
    pillLabel: 'Stopping...',
    pillStyle: '',
    bubbleStyle: 'bg-theme-danger-100 text-theme-danger-600',
    stripeStyle: 'bg-theme-danger-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.busy]: {
    pillLabel: 'Busy...',
    pillStyle: '',
    bubbleStyle: '',
    stripeStyle: '',
    backgroundStyle: '',
  },
};

const InstanceCard = ({
  id,
  gpuCount,
  gpuModelName,
  gpuRamGB,
  storageCapacity,
  cpuCores,
  ramGB,
  location,
  upload,
  download,
  maxDays,
  endOfYear,
  gpuPrice,
  pricePeriod,
  expansion,
  actionButton,
  status,
  onClick,
}: {
  id: string;
  gpuCount: number;
  gpuModelName: string;
  gpuRamGB: number;
  storageCapacity: string;
  cpuCores?: number;
  ramGB?: string;
  location?: string;
  upload?: number;
  download?: number;
  maxDays?: number;
  endOfYear: Date;
  gpuPrice: number;
  pricePeriod?: string;
  expansion?: ReactNode;
  actionButton?: ReactNode;
  status?: InstanceStatus;
  onClick?: any;
}) => {
  const [expanded, setExpanded] = useState(false);
  const statusConfig = status
    ? instanceStatusConfig[status]
    : defaultStatusConfig;
  return (
    <Card
      key={id}
      className={twClassnames(
        'flex flex-col text-theme-neutral-700 transition-all relative overflow-clip pb-5',
        {
          'shadow-theme-01 h-[168px]': expanded,
          'h-[100px]': expansion,
        },
        statusConfig.backgroundStyle
      )}
      noHover={!(expansion || onClick)}
      onClick={onClick ? onClick : () => expansion && setExpanded(!expanded)}
    >
      {status && (
        <div className="absolute left-0 top-0 py-2 h-full">
          <div
            className={twClassnames(
              'h-full w-1 rounded-full',
              statusConfig.stripeStyle
            )}
          />
        </div>
      )}
      <div
        className={twClassnames('flex gap-5', {
          'border-b border-theme-primary-100 pb-4': status && expansion,
        })}
      >
        {/* quantity */}
        <div className="hidden sm:flex ml-2 mr-11 items-center">
          <div
            className={twClassnames(
              'h-14 w-14 flex items-center justify-center rounded-full bg-theme-primary-100 text-theme-primary-600 text-lg font-bold',
              { [statusConfig.bubbleStyle]: status }
            )}
          >
            {gpuCount} X
          </div>
        </div>

        {/* gpu type */}
        <div className="flex flex-col flex-1 gap-1 items-start">
          <div className="text-theme-primary-600 font-semibold">
            {gpuModelName}
          </div>
          <div className="text-theme-neutral-600 text-xs">
            GPU RAM: {gpuRamGB}GB
          </div>
          {status && (
            <Badge
              text={statusConfig.pillLabel}
              className={twClassnames(
                'px-1.5 py-0 rounded-[4px] font-light text-xs',
                {
                  'bg-white border border-theme-success-700 text-theme-success-700':
                    status === InstanceStatus.online,
                  'bg-white border border-theme-neutral-200 text-theme-neutral-600':
                    status === InstanceStatus.offline,
                  'bg-theme-primary-100 text-theme-primary-600':
                    status === InstanceStatus.starting,
                  'border-theme-danger-600 bg-white text-theme-danger-600':
                    status === InstanceStatus.stopping,
                }
              )}
            />
          )}
        </div>

        {/* storage */}
        <div className="flex-1 hidden sm:flex flex-col gap-1 text-theme-neutral-700">
          <div>{storageCapacity}</div>
          <div className="text-theme-neutral-600 text-xs whitespace-nowrap flex flex-col gap-1">
            {cpuCores && (
              <div className="flex gap-1 max-w-32">
                <div>CPU Cores:</div>
                <div>{cpuCores}</div>
              </div>
            )}
            {ramGB && (
              <div className="flex gap-1 max-w-32">
                <div>RAM:</div>
                <div>{ramGB}</div>
              </div>
            )}
          </div>
        </div>

        {/* location */}
        <div className="flex-1 hidden lg:flex flex-col gap-1 text-theme-neutral-700">
          <div>{location}</div>
          <div className="flex flex-col text-theme-neutral-600 text-xs whitespace-nowrap">
            {upload && (
              <div className="flex items-center">
                <FiArrowUp className="inline mr-2 text-theme-primary-600" />
                {upload} Mbps
              </div>
            )}
            {download && (
              <div className="flex items-center">
                <FiArrowDown className="inline mr-2 text-theme-primary-600" />
                {download} Mbps
              </div>
            )}
          </div>
        </div>

        {/* duration */}
        <div className="flex-1 hidden lg:flex flex-col gap-1 text-theme-neutral-700">
          <div>Max: {maxDays}d</div>
          <div className="flex flex-col text-theme-neutral-600 text-xs">
            <div>Until ~ {format(endOfYear, 'MM/dd')}</div>
            {/* <div>Min: none</div> */}
          </div>
        </div>

        {/* price */}
        <div className="flex-1 flex flex-col gap-1">
          <div className="text-theme-primary-600 font-medium">
            {formatCurrency(gpuPrice)} / GPU / {displayPricePeriod(pricePeriod)}
          </div>
          {actionButton}
        </div>
      </div>

      {status === InstanceStatus.starting && <LoadingLine />}

      {expansion && (
        <div
          className={twClassnames(
            'bg-white mt-4 -mb-4 -mx-5 px-5 pl-6 py-4 rounded-bl-xl rounded-br-xl flex text-xs text-theme-neutral-600',
            { 'pt-0 pb-4': status }
          )}
        >
          {expansion}
        </div>
      )}
    </Card>
  );
};

export default InstanceCard;

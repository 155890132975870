import Slider from 'rc-slider';
import Input from './Input';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import { ReactNode } from 'react';
import twClassnames from '../../utils/classnames';
import 'rc-slider/assets/index.css';

interface InputSliderProps {
  id: string;
  label: string;
  name: string;
  onChange: (text: any) => void;
  value?: number;
  defaultValue?: number;
  min: number;
  max: number;
  step: number;
  error?: string;
  infoTooltip?: ReactNode;
}

const InputSlider = ({
  id,
  label,
  value,
  onChange,
  min,
  max,
  step,
  error,
  infoTooltip,
}: InputSliderProps) => {
  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-center justify-between">
        <div className="text-black mr-3 flex items-center text-sm">
          {label}
          {infoTooltip && (
            <AiOutlineInfoCircle
              data-tooltip-id={`info-${id}`}
              className="ml-2 text-theme-neutral-600 outline-theme-primary-700"
            />
          )}
          <Tooltip id={`info-${id}`} className="z-10" place="top">
            {infoTooltip}
          </Tooltip>
        </div>
        <Input
          className={twClassnames(
            'w-20 border border-theme-primary-200 hover:bg-theme-primary-50 text-black py-1.5 px-4 text-center rounded-[14px]',
            { 'border-theme-danger-600 outline-theme-danger-600': !!error }
          )}
          min={min}
          max={max}
          step={step}
          value={value}
          onChangeText={onChange}
          error={!!error}
        />
      </div>
      <div className="w-full mt-4">
        <Slider
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          classNames={{
            rail: '!bg-theme-neutral-300 !h-[6px]',
            track: twClassnames('!h-[6px]', {
              '!bg-theme-danger-600': !!error,
              '!bg-theme-primary-600': !error,
            }),
            handle: twClassnames(
              '!border-theme-primary-600 !opacity-100 !h-5 !w-5 !-mt-[7px] !shadow-none z-10',
              { '!border-theme-danger-600': !!error }
            ),
          }}
        />
      </div>
      {error && <div className="text-theme-danger-600 mt-2">{error}</div>}
    </div>
  );
};

export default InputSlider;

import { useNavigate } from 'react-router-dom';
import NewMachineForm from '../components/Compute/NewMachineForm';
import { createMachineRequestDb } from '../services/machines';

const NewMachineRequest = () => {
  const navigate = useNavigate();
  const handleSubmit = (data: any) => {
    createMachineRequestDb(data);
    navigate('/compute');
  };
  return (
    <div className="flex flex-col w-full px-10">
      <h1>Request GPU</h1>
      <NewMachineForm onSubmit={handleSubmit} />
    </div>
  );
};

export default NewMachineRequest;

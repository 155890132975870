import merge from 'lodash/merge';
import { auth } from './firebase';
import { API_ERRORS } from './constants';

const hyperFetch = async (path: string, options?: any) => {
  const accessToken = await (auth.currentUser?.getIdToken() ||
    localStorage.getItem('authToken'));
  const response = await fetch(
    path,
    merge(
      {},
      {
        headers: {
          'Content-type': 'application/json',
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      },
      options
    )
  );
  const json = await response.json();
  if (!response.ok) {
    throw API_ERRORS[json.error_code] || json;
  }
  return json;
};

export default hyperFetch;

import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { v4 as uuidV4 } from 'uuid';

export interface Message {
  id: string;
  text: string;
  image?: string;
  created: number;
  from?: string;
}

interface ChatSlice {
  inputValue: string;
  audioValue: string;
  imageValue: string;
  messages: Message[];
  aiReader: any;
  aiResponse: string[];
  aiResponseLoading: boolean;
}

const initialState: ChatSlice = {
  inputValue: '',
  audioValue: '',
  imageValue: '',
  messages: [],
  aiReader: undefined,
  aiResponse: [],
  aiResponseLoading: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const existingIndex = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );
      if (existingIndex > -1) {
        state.messages[existingIndex] = action.payload;
      } else if (action.payload.reset) {
        state.messages = [action.payload];
      } else {
        state.messages.push(action.payload);
      }
    },
    setChatValue: (state, action) => {
      state.inputValue = action.payload;
    },
    setAudioValue: (state, action) => {
      state.audioValue = action.payload;
    },
    setImageValue: (state, action) => {
      state.imageValue = action.payload;
    },
    resetChat: () => initialState,
    setAIResponseLoading: (state, action) => {
      state.aiResponseLoading = action.payload;
    },
    setAIReader: (state, action) => {
      state.aiReader = action.payload;
    },
    addAIResponse: (state, action) => {
      state.aiResponse = state.aiResponse.concat(action.payload);
    },
    resetAIResponse: (state) => {
      state.aiResponse = initialState.aiResponse;
    },
  },
});

export const {
  addMessage,
  resetChat,
  setChatValue,
  setAudioValue,
  setImageValue,
  setAIResponseLoading,
  setAIReader,
  addAIResponse,
  resetAIResponse,
} = chatSlice.actions;

export const cancelAIResponse =
  () => (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    state.chat?.aiReader?.cancel();
    dispatch(setAIReader(undefined));
  };

export const finalizeAIMessage =
  () => (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    if (state.chat.aiResponse.length === 0) return;
    const responseMessage = {
      id: uuidV4(),
      created: Date.now(),
      text: state.chat.aiResponse.join(''),
      from: 'ai',
    };
    dispatch(addMessage(responseMessage));
    dispatch(resetAIResponse());
    dispatch(setAIResponseLoading(false));
    return responseMessage;
  };

export const getMessages = (state: RootState) => state.chat.messages;
export const getChatValue = (state: RootState) => state.chat.inputValue;
export const getImageValue = (state: RootState) => state.chat.imageValue;
export const getAudioValue = (state: RootState) => state.chat.audioValue;
export const getAIResponse = (state: RootState) => state.chat.aiResponse;
export const getAIResponseLoading = (state: RootState) =>
  state.chat.aiResponseLoading;

export default chatSlice.reducer;

import { ReactNode, forwardRef, useContext } from 'react';
import twClassnames from '../../../utils/classnames';
import { DropdownContext } from '.';
import { FaCheck } from 'react-icons/fa6';
import { Link, To } from 'react-router-dom';
interface DropdownMenuItem {
  children: ReactNode;
  active?: boolean;
  onClick?: () => void;
  to?: To;
  className?: string;
  childClassName?: string;
  hideCheck?: boolean;
}

const DropdownMenuItem = forwardRef<DropdownMenuItem, any>(({
  children,
  active = false,
  onClick,
  to = '',
  className,
  childClassName,
  hideCheck = false,
  ...otherProps
}, ref) => {
  const { setIsOpen } = useContext(DropdownContext);
  const Container = to ? Link : 'button';
  return (
    <Container
      className={twClassnames(
        'text-sm flex items-center px-4 py-2 text-theme-neutral-700 rounded-lg hover:text-theme-primary-600 w-full hover:bg-theme-primary-100 hover:text-theme-primary-600',
        className
      )}
      onClick={(e) => {
        onClick?.(e);
        setIsOpen(false);
      }}
      to={to}
      ref={ref}
      {...otherProps}
    >
      <div className={twClassnames('flex-1 text-left', childClassName)}>
        {children}
      </div>
      {!hideCheck &&
        (active ? (
          <FaCheck className="ml-2 text-theme-primary-600" size={16} />
        ) : (
          <div className="w-6" />
        ))}
    </Container>
  );
});

export default DropdownMenuItem;

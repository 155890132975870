import { Outlet } from 'react-router-dom';

const Settings = () => {
  return (
    <div className="flex flex-col w-full px-6 lg:px-16">
      <h2>Settings</h2>
      <Outlet />
      {/* <General /> */}
    </div>
  );
};

export default Settings;

import { useCallback, useEffect, useState } from 'react';

const useLocalStorage = (key: string) => {
  const [value, setValue] = useState<any>('');
  const handleStorage = useCallback(() => {
    const stringValue = localStorage.getItem(key);
    let newValue = stringValue;
    if (stringValue) {
      try {
        newValue = JSON.parse(stringValue);
      } catch {
        // continue
      }
    }
    setValue(newValue);
  }, [key]);

  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, [key, handleStorage]);

  const setItem = useCallback(
    (newValue: any) => {
      localStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    [key]
  );

  useEffect(() => {
    handleStorage();
  }, [handleStorage]);

  return [value, setItem] as [string, (value: any) => void];
};

export default useLocalStorage;

import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../slices/auth';
import Input from '../common/Input';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import PublicKeyDropzone from '../common/PublicKeyDropzone';
import LockIcon from '../common/icons/LockIcon';
import APIKey from '../common/APIKey';
import ResetIcon from '../common/icons/ResetIcon';
import HyperLink from '../common/HyperLink';
import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import { mobileStyles } from '../../utils/modals';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { SECOND } from '../../utils/constants';
import { FaCircleCheck } from 'react-icons/fa6';

const General = () => {
  const userInfo = useSelector(getUserInfo);
  const isMarketplaceV2Enabled = useFeatureFlagEnabled('marketplace_v2');
  const dispatch = useDispatch();
  const [showCheckmark, setShowCheckmark] = useState(false);

  return (
    <div className="flex flex-col mb-6 gap-6 divide-y">
      <div className="flex flex-col gap-4 mb-2">
        <div className="text-lg font-semibold text-theme-neutral-700">
          Profile
        </div>
        <div className="flex flex-col">
          <div className="text-sm text-theme-neutral-700 font-medium w-28 mb-3">
            Display Name
          </div>
          <Input
            value={userInfo?.name}
            containerClassName="flex-1 w-full max-w-[500px]"
            disabled
            endIcon={<LockIcon size={20} />}
            endIconClassName="mt-2 right-4 text-theme-neutral-400"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-sm text-theme-neutral-700 font-medium w-28 mb-3">
            Email
          </div>
          <Input
            value={userInfo?.email}
            containerClassName="flex-1 w-full max-w-[500px]"
            disabled
            endIcon={<LockIcon size={20} />}
            endIconClassName="mt-2 right-4 text-theme-neutral-400"
          />
        </div>
      </div>
      {userInfo?.api_key && (
        <div id="api-key" className="flex flex-col pt-6">
          <div className="text-lg font-semibold text-theme-neutral-700 mb-2">
            API Key
          </div>
          <div className="text-sm font-light mb-6">
            API keys allow you to securely access Hyperbolic's services. Please
            protect your key because anyone who has the key has full access to
            your account.
          </div>
          <APIKey
            // text="default"
            apiKey={userInfo?.api_key}
            containerClassName="bg-transparent border-0"
            className="bg-transparent rounded-none py-5 px-0 lg:w-auto border-t border-b border-theme-neutral-200"
            innerClassName="w-96"
            textClassName="w-20"
          />
        </div>
      )}
      {isMarketplaceV2Enabled && userInfo && (
        <div id="public-key" className="flex flex-col pt-6 mb-8">
          <div className="text-lg font-semibold text-theme-neutral-700 mb-2">
            SSH Public Key
          </div>
          <div className="text-sm font-light mb-6">
            Your public key is required to SSH into instances, and only needs to
            be configured once. Wondering how to get your public key?&nbsp;
            <HyperLink
              to="/support/marketplace"
              className="text-theme-neutral-700 underline hover:text-theme-neutral-600"
              state={{ initialQuestion: 3 }}
            >
              Check support
            </HyperLink>
            .
          </div>
          {userInfo?.public_key ? (
            <APIKey
              text="Public Key"
              apiKey={userInfo?.public_key}
              containerClassName="bg-transparent border-0"
              className="bg-transparent rounded-none py-5 px-0 lg:w-auto border-t border-b border-theme-neutral-200"
              innerClassName="w-96"
              textClassName="w-20"
              additionalButtons={
                showCheckmark ? (
                  <FaCircleCheck className="text-theme-primary-600" size={20} />
                ) : (
                  <ResetIcon
                    className="transition cursor-pointer text-theme-neutral-600 hover:text-theme-primary-600"
                    size={19}
                    onClick={() =>
                      dispatch(
                        openModal({
                          name: ModalName.ResetPublicKey,
                          styles: isMobile
                            ? mobileStyles
                            : { content: { maxWidth: 500 } },
                        })
                      )
                    }
                  />
                )
              }
            />
          ) : (
            <PublicKeyDropzone
              onUpload={() => {
                setShowCheckmark(true);
                setTimeout(() => setShowCheckmark(false), 5 * SECOND);
              }}
            />
          )}
        </div>
      )}
      {/* <div className="flex flex-col gap-4 pt-6">
        <div className="text-lg font-semibold text-theme-neutral-700">
          Delete User
        </div>
        <div className="text-sm">
          Once you delete your user, there is no way to retrieve your user data.
        </div>
        <div>
          <Button variant="danger-outline">Delete User</Button>
        </div>
      </div> */}
    </div>
  );
};

export default General;

import Markdown from 'react-markdown';
import termsMd from '../utils/terms.md?raw';
import '../components/Privacy/privacy.css';

const TermsOfUse = () => (
  <div id="privacy-terms" className="mx-16">
    <Markdown>{termsMd}</Markdown>
  </div>
);

export default TermsOfUse;

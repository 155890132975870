import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  AudioInputParameters,
  ImageInputParameters,
  Model,
  TextInputParameters,
} from '../../utils/types';
import {
  FormatFn,
  formatAudioCurlRequest,
  formatAudioPythonRequest,
  formatAudioTsRequest,
  formatBaseCurlRequest,
  formatBasePythonRequest,
  formatBaseTsRequest,
  formatCurlRequest,
  formatImageCurlRequest,
  formatImagePythonRequest,
  formatImageTsRequest,
  formatPythonRequest,
  formatTsRequest,
  formatVisionPythonRequest,
} from '../../utils/models';
import CopyButton from '../common/CopyButton';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../slices/auth';
import { isMobile } from 'react-device-detect';
import {
  getAIResponse,
  getAudioValue,
  getChatValue,
  getMessages,
} from '../../slices/chat';

const mapLangToVisionFormat: { [key: string]: FormatFn } = {
  python: formatVisionPythonRequest,
  ts: formatTsRequest,
  curl: formatCurlRequest,
};

const mapBaseToFormat: { [key: string]: any } = {
  python: formatBasePythonRequest,
  ts: formatBaseTsRequest,
  curl: formatBaseCurlRequest,
};
const mapLangToFormat: { [key: string]: FormatFn } = {
  python: formatPythonRequest,
  ts: formatTsRequest,
  curl: formatCurlRequest,
};
const mapLangToImageFormat: { [key: string]: any } = {
  python: formatImagePythonRequest,
  ts: formatImageTsRequest,
  curl: formatImageCurlRequest,
};
const mapLangToAudioFormat: { [key: string]: any } = {
  python: formatAudioPythonRequest,
  ts: formatAudioTsRequest,
  curl: formatAudioCurlRequest,
};
const mapLangToFormatFn: { [key: string]: any } = {
  vlm: mapLangToVisionFormat,
  llm: mapLangToFormat,
  image: mapLangToImageFormat,
  audio: mapLangToAudioFormat,
};
const defaultApiKey = '$HYPERBOLIC_API_KEY';
const defaultText = 'What can I do in LA?';
const defaultMessages = [{ role: 'user', content: defaultText }];
const defaultImageText = 'What is this image?';
const defaultImageMessages = [
  {
    role: 'user',
    content: defaultImageText,
    image: 'image',
    image_process_mode: 'default',
  },
];
const defaultAudioText =
  'Los Angeles! The City of Angels is a treasure trove of exciting experiences, iconic landmarks, and endless entertainment options.';

const ModelCodeSnippet = ({
  id,
  model,
  textInputParameters,
  imageInputParameters,
  audioInputParameters,
  language,
  type = 'llm',
  liveUpdates = false,
  modelName = '',
}: {
  id: string;
  model: Model | undefined;
  textInputParameters: TextInputParameters<number>;
  imageInputParameters: ImageInputParameters<number, boolean>;
  audioInputParameters: AudioInputParameters<number>;
  language: string;
  type?: string;
  liveUpdates?: boolean;
  modelName?: string;
}) => {
  const userInfo = useSelector(getUserInfo);
  const chatMessages = useSelector(getMessages);
  const chatValue = useSelector(getChatValue);
  const audioValue = useSelector(getAudioValue);
  const isVLM = type === 'vlm';
  const isBase = model?.subType === 'base';
  const formatFn = isBase
    ? mapBaseToFormat[language]
    : mapLangToFormatFn[type][language] || mapLangToFormatFn['llm'][language];
  const apiKey = isMobile ? defaultApiKey : userInfo?.api_key || defaultApiKey;
  const highlighterLang = language === 'curl' ? 'shell' : language;
  const aiResponse = useSelector(getAIResponse);
  const messages = [];
  if (textInputParameters.systemPrompt) {
    messages.unshift({
      role: 'system',
      content: textInputParameters.systemPrompt,
    });
  }
  const formattedHistory = chatMessages.map((msg) => ({
    role: msg.from === 'ai' ? 'assistant' : 'user',
    content: msg.text,
    ...(msg.image && { image: 'image', image_process_mode: 'default' }),
  }));
  const inputPrompt = chatValue || defaultText || '';
  const messagesWithHistory = messages
    .concat(formattedHistory)
    .concat(
      formattedHistory.length === 0
        ? [{ role: 'user', content: inputPrompt }]
        : []
    )
    .concat(
      aiResponse.length > 0
        ? [{ role: 'assistant', content: aiResponse.join('') }]
        : []
    );
  const audioText = liveUpdates
    ? audioValue || defaultAudioText
    : defaultAudioText;
  const messagesArr = liveUpdates
    ? messagesWithHistory
    : isVLM
    ? defaultImageMessages
    : defaultMessages;

  let codeString = formatFn?.(
    modelName,
    model?.url || '',
    textInputParameters,
    apiKey,
    messagesArr
  );
  if (type === 'image') {
    codeString = formatFn?.(
      modelName,
      model?.url || '',
      imageInputParameters,
      apiKey
    );
  } else if (type === 'audio') {
    codeString = formatFn?.(
      modelName,
      model?.url || '',
      audioInputParameters,
      apiKey,
      audioText
    );
  } else if (isBase) {
    codeString = formatFn?.(
      modelName,
      model?.url || '',
      textInputParameters,
      apiKey,
      inputPrompt
    );
  }
  return (
    <>
      <div className="relative mt-2 md:mt-6">
        <CopyButton
          id={id}
          className="absolute top-8 right-6 text-theme-neutral-600 hover:text-theme-primary-600"
          copyText={codeString}
        />
      </div>
      <SyntaxHighlighter
        PreTag="div"
        language={highlighterLang}
        style={oneLight}
        wrapLines
        wrapLongLines
        className="flex text-sm rounded-lg max-w-xs md:max-w-none border"
        customStyle={{ padding: '30px', overflow: 'unset' }}
      >
        {codeString}
      </SyntaxHighlighter>
    </>
  );
};

export default ModelCodeSnippet;

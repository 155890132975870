import Button from '../common/Button';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyButton from '../common/CopyButton';
import ModalClose from '../common/ModalClose';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import {
  getFormattedRentedInstance,
  terminateInstance,
} from '../../slices/instances';
import { FiTrash2 } from 'react-icons/fi';
import { InstanceStatus } from '../../utils/types';
import { usePostHog } from 'posthog-js/react';

const highlighterLang = 'shell';

const showToastDeleteSuccess = () =>
  toast.success('Terminated instance successfully');

const RentedInstanceModal = ({
  rentalId,
  onClose,
}: {
  rentalId: string;
  onClose: () => void;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const rental = useSelector((state: RootState) =>
    getFormattedRentedInstance(state, rentalId)
  );

  const sshCommand = rental?.sshCommand || '';

  const onSuccess = () => {
    setErrorMessage('');
    showToastDeleteSuccess();
    onClose();
  };

  const handleStop = async () => {
    setErrorMessage('');

    try {
      await dispatch(terminateInstance(rental?.id || ''));
      posthog?.capture('Rented Instance Terminated', { rentalId: rental?.id });
      onSuccess();
    } catch (e: any) {
      setErrorMessage(e?.message || 'Something went wrong');
    }
  };
  const isLoading = rental?.instance?.status === InstanceStatus.stopping;

  return (
    <div className="p-4">
      <ModalClose onClose={onClose} />
      <h2 className="text-center">{`My Instance - ${rental?.instance?.id}`}</h2>
      <div className="flex flex-col gap-4">
        <div>Connect to your instance using the following command:</div>
        <div className="relative">
          <CopyButton
            id="rented-instance-ssh-command"
            className="absolute top-4 right-3 text-theme-neutral-600 hover:text-theme-primary-600"
            copyText={sshCommand}
          />
          <SyntaxHighlighter
            PreTag="div"
            language={highlighterLang}
            style={vs}
            wrapLines
            wrapLongLines
            className="flex text-sm rounded-lg w-full border"
            customStyle={{ padding: '30px' }}
          >
            {sshCommand}
          </SyntaxHighlighter>
        </div>
        <div>You may stop renting this instance at any time</div>
        <div>
          <Button
            onClick={handleStop}
            className="flex items-center gap-2 shrink"
            variant="danger"
            isLoading={isLoading}
          >
            {isLoading ? (
              'Stopping'
            ) : (
              <>
                <FiTrash2 />
                <span>Stop Renting Now</span>
              </>
            )}
          </Button>
          {errorMessage && (
            <div className="text-theme-danger-500 mt-4">{errorMessage}</div>
          )}
        </div>

        {/* <div className="flex justify-end">
          <Button
            onClick={() => onClose()}
            className="flex items-center gap-2 shrink"
          >
            OK
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default RentedInstanceModal;

import hyperFetch from '../utils/fetch';

export const fetchUserInfoDb = async () => {
  const response = await hyperFetch(
    `${
      import.meta.env.VITE_USE_FIREBASE_DEV === 'true'
        ? 'https://api.dev-hyperbolic.xyz'
        : import.meta.env.VITE_BACKEND_HOST
    }/users/me`
  );
  return response;
};

export const updateUserInfoDb = async ({
  userId,
  update,
}: {
  userId: string;
  update: any;
}) =>
  hyperFetch(`${import.meta.env.VITE_BACKEND_HOST}/users/${userId}`, {
    method: 'PUT',
    body: JSON.stringify(update),
  });

export const updatePublicKeyDb = async (publicKey: string) => {
  await hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/v1/marketplace/update_public_key`,
    {
      method: 'POST',
      body: JSON.stringify({ public_key: publicKey }),
    }
  );
  return publicKey;
};

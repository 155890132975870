export type ButtonVariant =
  | 'primary'
  | 'outline'
  | 'neutral'
  | 'neutral-outline'
  | 'danger'
  | 'danger-outline'
  | 'link'
  | 'ghost'
  | 'ghost-danger'
  | 'dropdown'
  | 'simple';

export const buttonClasses = {
  primary:
    'border-theme-primary-600 text-white bg-theme-primary-600 hover:bg-theme-primary-700 hover:border-theme-primary-700 disabled:bg-theme-primary-300 disabled:border-theme-primary-300',
  outline:
    'bg-white border-theme-primary-600 text-theme-primary-600 hover:bg-theme-primary-100',
  neutral:
    'border-0 bg-theme-neutral-200 hover:bg-theme-neutral-300 text-theme-primary-600 hover:text-theme-primary-700 disabled:bg-theme-neutral-200 disabled:text-theme-neutral-500',
  'neutral-outline':
    'border-theme-neutral-600 bg-white text-theme-neutral-600 hover:bg-theme-neutral-200',
  danger:
    'bg-theme-danger-700 border-theme-danger-700 text-white hover:border-theme-danger-600 hover:bg-theme-danger-600 disabled:bg-theme-danger-300 disabled:border-theme-danger-300',
  'danger-outline':
    'border-theme-danger-600 text-theme-danger-700 bg-white hover:border-theme-danger-700 hover:bg-theme-danger-700 hover:text-white',
  link: 'hover:underline text-theme-primary-600 disabled:text-theme-primary-300',
  ghost:
    'border-0 text-theme-neutral-600 hover:text-theme-primary-600 hover:bg-theme-primary-100 disabled:text-theme-neutral-400',
  'ghost-danger':
    'border-0 text-theme-neutral-600 hover:text-theme-danger-700 hover:bg-theme-danger-100 disabled:text-theme-neutral-400',
  dropdown:
    'bg-white border-theme-primary-200 hover:border-theme-primary-600 text-neutral-700 hover:text-theme-primary-600 rounded-lg',
  simple:
    'inline-block px-4 py-2 bg-white text-theme-neutral-600 hover:text-theme-primary-600 disabled:text-theme-neutral-300',
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoDocumentTextOutline, IoImageOutline } from 'react-icons/io5';
import { AppDispatch } from '../store';
import {
  fetchModels,
  getModelsInitialLoading,
  getSortedModels,
} from '../slices/models';
import ModelCard from '../components/Models/ModelCard';
import { Tab, TabList, TabPanel, Tabs } from '../components/common/Tabs';
import { AiOutlineAudio } from 'react-icons/ai';
import NotificationIcon from '../components/common/icons/NotificationIcon';
import Banner from '../components/common/Banner';
import HyperLink from '../components/common/HyperLink';
import { discordLink, supportEmail } from '../utils/constants';

const ModelsSkeleton = () => (
  <>
    <div className="animate-pulse">
      <div className="h-[134px] bg-theme-neutral-200 rounded-xl w-full" />
    </div>
    <div className="animate-pulse">
      <div className="h-[134px] bg-theme-neutral-200 rounded-xl w-full" />
    </div>
    <div className="animate-pulse">
      <div className="h-[134px] bg-theme-neutral-200 rounded-xl w-full" />
    </div>
  </>
);

const Models = () => {
  const dispatch = useDispatch<AppDispatch>();
  const models = useSelector(getSortedModels);
  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);
  const initialLoading = useSelector(getModelsInitialLoading);
  const textModels = models.filter((model) => model.type === 'llm');
  const imageModels = models.filter((model) => model.type === 'image');
  const audioModels = models.filter((model) => model.type === 'audio');
  const vlmModels = models.filter((model) => model.type === 'vlm');
  const hasVlmModels = vlmModels.length > 0 && !initialLoading;
  return (
    <div className="flex flex-col w-full px-6 lg:px-16">
      <Banner
        storageKey="hostedModelsClicked"
        className="px-6 md:px-10 gap-2 md:gap-20 justify-between md:justify-center"
      >
        <div>
          Interested in hosting your own model? Contact our team at&nbsp;
          <HyperLink href={`mailto:${supportEmail}`}>
            {supportEmail}
          </HyperLink>
          &nbsp; or reach out in&nbsp;
          <HyperLink href={discordLink}>
            Discord
          </HyperLink>
          !
        </div>
      </Banner>
      <Tabs>
        <div className="sticky top-[88px] md:top-[60px] bg-white z-[5] pb-1">
          <h2>Models</h2>
          <TabList>
            <Tab>All</Tab>
            {hasVlmModels && <Tab>VLM</Tab>}
            <Tab>Text</Tab>
            <Tab>Image</Tab>
            <Tab>Audio</Tab>
          </TabList>
        </div>

        <div className="mb-8">
          <TabPanel>
            {hasVlmModels && (
              <>
                <div className="flex items-center text-theme-neutral-700 mt-6 mb-5 text-sm">
                  <NotificationIcon className="mr-3" size={16} />
                  <div>Vision Language Models</div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                  {initialLoading ? (
                    <ModelsSkeleton />
                  ) : vlmModels.length > 0 ? (
                    vlmModels.map((model) => (
                      <ModelCard key={model.slug} {...model} />
                    ))
                  ) : (
                    <div>No VLM models yet</div>
                  )}
                </div>
              </>
            )}
            <div className="flex items-center text-theme-neutral-700 mt-6 mb-5 text-sm">
              <IoDocumentTextOutline className="mr-3" size={16} />
              <div>Text Generation</div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              {initialLoading ? (
                <ModelsSkeleton />
              ) : (
                textModels.map((model) => (
                  <ModelCard key={model.slug} {...model} />
                ))
              )}
            </div>
            <div className="flex items-center text-theme-neutral-700 mt-6 mb-5 text-sm">
              <IoImageOutline className="mr-3" size={16} />
              <div>Image Generation</div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              {initialLoading ? (
                <ModelsSkeleton />
              ) : imageModels.length > 0 ? (
                imageModels.map((model) => (
                  <ModelCard key={model.slug} {...model} />
                ))
              ) : (
                <div>No image models yet</div>
              )}
            </div>
            <div className="flex items-center text-theme-neutral-700 mt-6 mb-5 text-sm">
              <AiOutlineAudio className="mr-3" size={16} />
              <div>Audio Generation</div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              {initialLoading ? (
                <ModelsSkeleton />
              ) : audioModels.length > 0 ? (
                audioModels.map((model) => (
                  <ModelCard key={model.slug} {...model} />
                ))
              ) : (
                <div>No audio models yet</div>
              )}
            </div>
          </TabPanel>
          {hasVlmModels && (
            <TabPanel>
              <div className="grid grid-cols-1 mt-4 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                {vlmModels.map((model) => (
                  <ModelCard key={model.slug} {...model} />
                ))}
              </div>
            </TabPanel>
          )}
          <TabPanel>
            <div className="grid grid-cols-1 mt-4 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
              {textModels.map((model) => (
                <ModelCard key={model.slug} {...model} />
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="grid grid-cols-1 mt-4 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
              {imageModels.map((model) => (
                <ModelCard key={model.slug} {...model} />
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="grid grid-cols-1 mt-4 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
              {audioModels.map((model) => (
                <ModelCard key={model.slug} {...model} />
              ))}
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default Models;

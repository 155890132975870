import { InputHTMLAttributes, ReactElement, forwardRef } from 'react';
import twClassnames from '../../utils/classnames';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeText?: (text: string) => void;
  containerClassName?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  endIconClassName?: string;
  className?: string;
  error?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      onChangeText,
      containerClassName,
      className,
      startIcon,
      endIcon,
      endIconClassName,
      error,
      ...otherProps
    },
    ref
  ) => (
    <div className={twClassnames('relative flex', containerClassName)}>
      {startIcon && (
        <div className="absolute left-4 text-theme-neutral-600 mt-3.5">
          {startIcon}
        </div>
      )}
      <input
        ref={ref}
        value={value}
        onChange={(e) => {
          onChange?.(e);
          onChangeText?.(e.target.value);
        }}
        className={twClassnames(
          'text-sm border border-theme-primary-200 text-black py-2 px-4 rounded-lg bg-white placeholder:text-theme-neutral-600 w-full disabled:border-theme-neutral-200 disabled:text-theme-neutral-500 disabled:bg-theme-neutral-100 outline-theme-primary-600',
          {
            'pl-12': !!startIcon,
            'pr-12': !!endIcon,
            'border-theme-danger-600 outline-theme-danger-600': !!error,
          },
          className
        )}
        {...otherProps}
      />
      {endIcon && (
        <div
          className={twClassnames(
            'absolute right-3 text-theme-neutral-600 mt-3.5',
            endIconClassName
          )}
        >
          {endIcon}
        </div>
      )}
    </div>
  )
);

export default Input;

import ModalClose from '../common/ModalClose';
import { AddFunds } from '../common/AddFunds';

const AddFundsModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="p-4">
      <ModalClose className="right-6 top-6" onClose={onClose} />
      <AddFunds />
    </div>
  );
};

export default AddFundsModal;

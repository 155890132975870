import { intervalToDuration } from 'date-fns';
import { displayDuration } from './instances';
import { HOUR } from './constants';

export const displayRuntime = (ratePerHour: number, credits: number) => {
  const hours = credits / ratePerHour;
  const seconds = hours * HOUR;
  const now = new Date();
  const later = new Date(now.getTime() + seconds);
  return displayDuration(intervalToDuration({ start: now, end: later }));
};

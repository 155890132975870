import { auth } from './utils/firebase';
import { Provider } from 'react-redux';

import { usePostHog } from 'posthog-js/react';
import { onAuthStateChanged } from '@firebase/auth';
import { useCallback, useEffect, useRef } from 'react';
import store from './store';
import { fetchUserInfo, resetUser, setUser } from './slices/auth';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { fetchBalance, resetUsage } from './slices/usage';
import { isProdEnv } from './utils/constants';
import router from './router';

Sentry.init({
  dsn: 'https://e6aa7df0077f8a1aad97b736414e1fdb@o4506633600172032.ingest.us.sentry.io/4507058733711360',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ['localhost', import.meta.env.VITE_BACKEND_HOST],
  environment: isProdEnv ? 'production' : 'development',
});

function Root() {
  const posthog = usePostHog();
  const authStateListenerRef = useRef<any>(null);

  const setupAuthStateChange = useCallback(() => {
    return onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Send event to PostHog with the user id
        posthog?.identify(user.uid, {
          email: user.email,
        });
        store.dispatch(setUser({ user, provider: null } as any));
        store.dispatch(fetchBalance());
        const idToken = await user.getIdToken();
        localStorage.setItem('authToken', idToken);
        await store.dispatch(fetchUserInfo());
      } else {
        store.dispatch(resetUser());
        store.dispatch(resetUsage());
        localStorage.removeItem('authToken');
      }
    });
  }, [posthog]);

  useEffect(() => {
    if (!authStateListenerRef.current) {
      authStateListenerRef.current = setupAuthStateChange();
    } else {
      authStateListenerRef.current();
      authStateListenerRef.current = setupAuthStateChange();
    }
  }, [setupAuthStateChange]);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default Root;

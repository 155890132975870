import { IoDocumentTextOutline } from 'react-icons/io5';
import HyperLink from '../common/HyperLink';
import ChartBreakoutIcon from '../common/icons/ChartBreakoutIcon';
import { supportSections } from '../../utils/support';

const FAQs = () => (
  <>
    <div className="flex gap-5">
      <HyperLink
        href="https://calendly.com/jeremhazan/hyperbolic-sales-demo"
        className="transition py-6 flex flex-col gap-3 items-center justify-center w-64 shadow-theme-02 rounded-lg border border-theme-primary-200 hover:no-underline hover:border-theme-primary-600"
        rel="noreferrer noopener"
        target="_blank"
      >
        <ChartBreakoutIcon className="text-theme-primary-600" />
        <div className="text-black font-semibold text-base">Book a Demo</div>
      </HyperLink>
      <HyperLink
        href="https://docs.hyperbolic.xyz"
        className="transition py-6 flex flex-col gap-3 items-center justify-center w-64 shadow-theme-02 rounded-lg border border-theme-primary-200 hover:no-underline hover:border-theme-primary-600"
        rel="noreferrer noopener"
        target="_blank"
      >
        <IoDocumentTextOutline size={28} className="text-theme-primary-600" />
        <div className="text-black text-base font-semibold">Access Docs</div>
      </HyperLink>
    </div>
    <div className="w-full h-[1px] bg-theme-neutral-200" />
    <div className="text-lg font-semibold text-black">FAQs</div>
    <div className="bg-theme-neutral-50 rounded-lg p-8 flex flex-wrap gap-16 lg:gap-28">
      {supportSections.map((section) => (
        <div key={section.title} className="text-sm flex flex-col gap-8">
          <div className="text-black text-base font-semibold">
            {section.title}
          </div>
          <div className="flex flex-col gap-2 text-black flex-1">
            {section.questions.map((q, i) => (
              <HyperLink
                key={`link-${section.id}-${q.question}`}
                to={`/support/${section.id}`}
                state={{ initialQuestion: i }}
                className="text-theme-neutral-700 hover:text-theme-neutral-600"
              >
                {q.question}
              </HyperLink>
            ))}
          </div>
          <HyperLink to={`/support/${section.id}`} className="font-semibold">
            View all questions
          </HyperLink>
        </div>
      ))}
    </div>
  </>
);

export default FAQs;

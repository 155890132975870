import { useDispatch, useSelector } from 'react-redux';
import { convertKeysToSnake } from '../utils/models';
import { getTextInputParameters } from '../slices/models';
import { useRef } from 'react';
import { decodeUtf8 } from '../utils/text-encoding';
import { auth } from '../utils/firebase';
import omit from 'lodash/omit';
import { Model } from '../utils/types';
import {
  Message,
  addAIResponse,
  finalizeAIMessage,
  getAIResponseLoading,
  setAIReader,
  setAIResponseLoading,
} from '../slices/chat';
import { AppDispatch } from '../store';
import { formatMessagesWithHistory } from '../utils/chat';

const useAIResponse = ({
  model,
  chatMessages,
  modelName = 'NousResearch/Nous-Hermes-2-Mixtral-8x7B-DPO',
}: {
  model?: Model;
  chatMessages: Message[];
  modelName?: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const inputParameters = useSelector(getTextInputParameters);
  const unfinishedData = useRef<string>('');
  const isLoading = useSelector(getAIResponseLoading);
  const modelUrl =
    model?.url || 'http://142.202.69.115:8000/v1/chat/completions';

  const handleAIResponse = async (text: string, image?: string) => {
    const accessToken = await auth.currentUser?.getIdToken();
    dispatch(setAIResponseLoading(true));
    const params = omit(inputParameters.values, ['systemPrompt']);
    const messagesWithHistory = formatMessagesWithHistory(
      text,
      chatMessages,
      inputParameters.values.systemPrompt,
      image
    );
    const payload =
      model?.subType === 'base'
        ? { prompt: text }
        : { messages: messagesWithHistory };
    const response = await fetch(modelUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${accessToken}`,
      },
      body: JSON.stringify({
        model: modelName,
        stream: true,
        ...payload,
        ...convertKeysToSnake(params),
      }),
    });
    const reader = response.body?.getReader();
    dispatch(setAIReader(reader));

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const reading = await reader?.read();
      const { done, value } = reading || {};
      if (done) {
        dispatch(finalizeAIMessage());
        break;
      }
      let decodedValue = decodeUtf8(value);
      if (unfinishedData.current) {
        decodedValue = unfinishedData.current + decodedValue;
        unfinishedData.current = '';
      }
      const dataArr = decodedValue
        .split('\n\n')
        .map((d) => {
          const jsonString = d.replace('data: ', '').trim();
          try {
            const jsonData = JSON.parse(jsonString);
            return model?.subType === 'base'
              ? jsonData.choices[0].text
              : jsonData.choices[0].delta.content;
          } catch (e) {
            unfinishedData.current = jsonString;
            return null;
          }
        })
        .filter((d) => !!d);
      dispatch(addAIResponse(dataArr));
    }
  };

  return { handleAIResponse, isLoading };
};

export default useAIResponse;

import { createElement } from 'react';

const convertTextToId = (text: string, tagName: string) => {
  const match = text.match(/([\w\s]+)\{#([\w-]+)\}/);
  if (match) {
    return [match[1], match[2]];
  }
  return [text, generateId(tagName)];
};

const generateId = (() => {
  let i = 0;
  return (tagName: string) => {
    const id = `${tagName}-${i}`;
    i += 1;
    return id;
  };
})();

const HeadingRenderer = (props: any) => {
  const { node, ...rest } = props;
  const [children, id] =
    typeof props.children === 'string'
      ? convertTextToId(props.children, node.tagName)
      : [props.children, generateId(node.tagName)];
  return createElement(node.tagName, { ...rest, id }, children);
};

export default HeadingRenderer;

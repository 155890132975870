import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { AppDispatch, RootState } from '../../store';
import {
  changeImageParameter,
  getGeneratedImagesError,
  getImageInputParameters,
  getModel,
} from '../../slices/models';
import InputSlider from '../common/InputSlider';
import TextAreaContainer from '../common/TextAreaContainer';
import SwitchContainer from '../common/SwitchContainer';
import ResolutionDropdown from './ResolutionDropdown';
import twClassnames from '../../utils/classnames';
import { useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { LuImagePlus } from 'react-icons/lu';
import { Tooltip } from 'react-tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';

const ImageInputParametersForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const inputParameters = useSelector(getImageInputParameters);
  const generatedImagesError = useSelector(getGeneratedImagesError);
  const { modelId } = useParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const [error, setError] = useState('');
  const [imageFile, setImageFile] = useState<any>(null);
  const handleChange =
    (name: string) => (value: number | boolean | string | undefined) => {
      dispatch(changeImageParameter({ id: name, value }));
    };
  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onabort = () => setError('File reading was aborted');
    reader.onerror = () => setError('File reading has failed');
    reader.onload = () => {
      const dataURL = reader.result;
      setImageFile(file);
      const image = String(dataURL).replace(
        /data:image\/(jpg|png|jpeg);base64,/,
        ''
      );
      handleChange('image')(image);
      handleChange('strength')(0.8);
    };
    reader.readAsDataURL(file);
  };
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      accept: { 'image/*': ['.png', '.jpeg', '.jpg'] },
    });
  const fileError =
    error ||
    (fileRejections.length > 0 && fileRejections[0].errors?.[0].message);
  const imageURL = inputParameters.values.image;
  return (
    <div>
      {!model?.hiddenInputs?.includes('enableReference') && (
        <SwitchContainer
          id="reference-image"
          label="Image to image"
          selected={inputParameters.values.enableReference || false}
          onSelect={handleChange('enableReference')}
          labelClassName="text-theme-neutral-600"
        />
      )}
      <TextAreaContainer
        id="text-prompt"
        label="Prompt"
        name="prompt"
        placeholder="User prompt: Kanye west riding a horse on Mars"
        className="min-h-28"
        maxLength={1000}
        value={inputParameters.values.prompt}
        error={inputParameters.errors.prompt}
        onChange={(e) => handleChange('prompt')(e.target.value)}
        infoTooltip="Tells the generation process what to include in the image"
      />
      {!model?.hiddenInputs?.includes('negativePrompt') && (
        <TextAreaContainer
          id="negative-prompt"
          label="Negative Prompt"
          name="negativePrompt"
          placeholder="Describe what you do NOT want to generate here"
          className="min-h-28"
          maxLength={1000}
          value={inputParameters.values.negativePrompt}
          error={inputParameters.errors.negativePrompt}
          onChange={(e) => handleChange('negativePrompt')(e.target.value)}
          infoTooltip="Tells the generation process what not to include in the image"
        />
      )}
      {inputParameters.values.enableReference && (
        <div className="flex flex-col">
          <label className="text-black mr-3 mb-2 flex items-center text-sm">
            Reference Image
          </label>
          {imageURL ? (
            <div>
              <div className="flex items-center mb-4">
                <img
                  src={`data:image/png;base64,${imageURL}`}
                  className="h-12 w-12 mr-3"
                />
                <div className="flex-1">
                  {imageFile?.name || 'reference.png'}
                </div>
                <FiXCircle
                  className="transition text-theme-neutral-700 hover:text-theme-danger-600 cursor-pointer"
                  size={24}
                  onClick={() => {
                    setImageFile(null);
                    handleChange('image')(undefined);
                    handleChange('strength')(undefined);
                  }}
                />
              </div>
              <InputSlider
                id="strength-slider"
                label="Strength"
                name="strength"
                min={0}
                max={1}
                step={0.1}
                defaultValue={0.8}
                value={inputParameters.values.strength}
                error={inputParameters.errors.strength}
                onChange={handleChange('strength')}
                infoTooltip="The level of noise added to the original image."
              />
            </div>
          ) : (
            <div
              {...getRootProps({
                className: twClassnames(
                  'transition h-32 flex items-center justify-center text-center px-4 py-3 border-2 border-dashed border-theme-primary-200 bg-white rounded-lg cursor-pointer text-theme-neutral-400 hover:text-theme-primary-600 hover:border-theme-primary-600 hover:bg-theme-primary-100 mb-4',
                  {
                    '!border-theme-primary-600 !bg-theme-primary-100':
                      isDragActive,
                  },
                  {
                    '!border-theme-danger-600 !bg-theme-danger-200':
                      !!fileError,
                  }
                ),
              })}
            >
              <input {...getInputProps()} />
              {fileError ? (
                <div>{fileError}</div>
              ) : (
                <div className="flex flex-col justify-center items-center gap-1">
                  <LuImagePlus size={20} />
                  <div className="font-light">Upload reference image here</div>
                  <div className="text-xs">JPG or PNG</div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col mb-4">
        <label className="text-black mb-2 text-sm flex items-center">
          Resolution
          <AiOutlineInfoCircle
            data-tooltip-id="info-resolution"
            className="ml-2 text-theme-neutral-600 outline-theme-primary-700"
          />
          <Tooltip id="info-resolution" className="z-10" place="top">
            Height x Width of the generated image
          </Tooltip>
        </label>
        <ResolutionDropdown />
      </div>
      <InputSlider
        id="steps-slider"
        label="Steps"
        name="steps"
        min={1}
        max={50}
        step={1}
        defaultValue={30}
        value={inputParameters.values.steps}
        error={inputParameters.errors.steps}
        onChange={handleChange('steps')}
        infoTooltip="The number of inference steps to generate the image -- generally, the higher the steps, the higher the resolution."
      />
      <InputSlider
        id="cfg-slider"
        label="CFG Scale"
        name="cfgScale"
        min={1}
        max={50}
        step={1}
        defaultValue={12}
        value={inputParameters.values.cfgScale}
        error={inputParameters.errors.cfgScale}
        onChange={handleChange('cfgScale')}
        infoTooltip="How closely the image generations follows the text prompt"
      />
      {model?.model === 'SDXL1.0-base' && (
        <SwitchContainer
          id="enable-refiner"
          label="Enable Refiner"
          selected={inputParameters.values.enableRefiner}
          error={inputParameters.errors.enableRefiner}
          onSelect={handleChange('enableRefiner')}
          infoTooltip="The refiner is an additional SD model run again on the generated image to optimize the quality of the image."
        />
      )}
      {generatedImagesError && (
        <div className="text-theme-danger-600">{generatedImagesError}</div>
      )}
    </div>
  );
};

export default ImageInputParametersForm;

import Markdown from 'react-markdown';
import privacyMd from '../utils/privacy.md?raw';
import '../components/Privacy/privacy.css';
import HeadingRenderer from '../components/Privacy/HeadingRenderer';

const Privacy = () => (
  <div id="privacy-terms" className="mx-16">
    <Markdown components={{ h2: HeadingRenderer }}>{privacyMd}</Markdown>
  </div>
);

export default Privacy;

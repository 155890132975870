import { useForm } from 'react-hook-form';
import ModalClose from '../common/ModalClose';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import { Option } from '../../utils/types';
import { gpuTypes } from '../../utils/instances';
import useUser from '../../hooks/useUser';
import Button from '../common/Button';
import {
  createMachineDb,
  createMachineRequestDb,
  submitHubspotWaitlist,
} from '../../services/machines';
import FormNumberStepper from '../FormNumberStepper';
import { ButtonGroup, ButtonGroupButton } from '../common/ButtonGroup';
import { useState } from 'react';
import toast from 'react-hot-toast';

export type RequestGPUInputs = {
  email: string;
  gpuModel: Option;
  gpuCount: number;
  company?: string;
  phoneNumber?: string;
};

const GPURequestModal = ({
  onClose,
  mode = 'request',
}: {
  onClose: () => void;
  mode?: 'request' | 'supply';
}) => {
  const { control, handleSubmit, formState, getValues } =
    useForm<RequestGPUInputs>();
  const [contactMode, setContactMode] = useState<string>('email');
  const { user } = useUser();
  const onSubmit = (data: RequestGPUInputs) => {
    const contactFunc =
      mode === 'supply' ? createMachineDb : createMachineRequestDb;
    const fields = Object.entries({ ...data, mode }).map(([key, value]) => ({
      key,
      value,
    }));
    submitHubspotWaitlist({ fields });
    toast.promise(contactFunc(data), {
      loading: 'Sending...',
      success: 'Thank you for joining the waitlist!',
      error: 'Failure to join waitlist. Please try again!',
    });
    onClose();
  };
  return (
    <div className="p-6 flex flex-col gap-6">
      <ModalClose onClose={onClose} />
      <div className="text-center text-lg font-semibold text-black">
        Join Waitlist
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          control={control}
          defaultValue={user?.displayName || ''}
          label="Name"
          name="name"
          rules={{ required: true }}
        />
        <FormInput
          control={control}
          defaultValue=""
          label="Company / University"
          name="company"
        />
        <FormSelect
          control={control}
          options={gpuTypes}
          label="GPU model"
          name="gpuModel"
          rules={{ required: true }}
          menuClassName="overflow-y-auto max-h-52"
          placeholder={
            mode === 'supply'
              ? 'Please select the GPU type you plan to provide'
              : 'Please select the GPU type you need'
          }
        />
        <FormNumberStepper
          control={control}
          defaultValue={1}
          label="GPU count"
          name="gpuCount"
          rules={{ required: true, minValue: 1 }}
        />
        <div className="mb-2 font-semibold flex text-black text-sm">
          Notify by email or SMS?*
        </div>
        <ButtonGroup className="mb-3">
          <ButtonGroupButton
            type="button"
            active={contactMode === 'email'}
            onClick={() => setContactMode('email')}
          >
            Email
          </ButtonGroupButton>
          <ButtonGroupButton
            type="button"
            active={contactMode === 'sms'}
            onClick={() => setContactMode('sms')}
          >
            SMS
          </ButtonGroupButton>
        </ButtonGroup>
        <div>
          {contactMode === 'email' && (
            <FormInput
              control={control}
              defaultValue={user?.email || ''}
              type="email"
              name="email"
              placeholder="Email"
              rules={{ required: !getValues().phoneNumber }}
            />
          )}
          {contactMode === 'sms' && (
            <FormInput
              control={control}
              defaultValue=""
              name="phoneNumber"
              placeholder="Phone number"
              rules={{ required: !getValues().email }}
            />
          )}
        </div>
        <div className="flex gap-6 mt-6">
          <Button
            variant="outline"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className="flex-1"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="flex-1"
            disabled={!formState.isValid}
          >
            Join waitlist
          </Button>
        </div>
      </form>
    </div>
  );
};

export default GPURequestModal;

export const isProdBuild = process.env.NODE_ENV === 'production';
export const isProdEnv =
  isProdBuild && window?.location.href.includes('app.hyperbolic.xyz');

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const API_ERRORS: { [key: number]: { message: string } } = {
  100: {
    message: "You don't have enough credits!",
  },
};

export const discordLink = 'https://discord.gg/hyperbolic';
export const twitterLink = 'https://twitter.com/hyperbolic_labs';
export const supportEmail = 'support@hyperbolic.xyz';

// Posthog config
export const POSTHOG_HOST = 'https://ph.hyperbolic.xyz';
export const POSTHOG_KEY = isProdEnv
  ? 'phc_Vd2H9ikzOYqQID0gnI4yvTlhLBzKcagOcZ2uuSbQfgS'
  : 'phc_aTeJ3t31v8gxMOZshhci6OA73c9apCwCQzWjwpBknlT';
export const POSTHOG_OPTIONS = {
  api_host: POSTHOG_HOST,
  ui_host: 'https://us.posthog.com',
  bootstrap: {
    featureFlags: {
      compute: true,
      team: false,
      billing: false,
      marketplace_v2: false,
      rent: false,
      supply: false,
      instances: false,
    },
  },
};

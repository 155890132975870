import useUser from '../../hooks/useUser';
import ModalClose from '../common/ModalClose';
import inferenceImage from '../../assets/inference-product.png';
import marketplaceImage from '../../assets/marketplace-product.png';
import Button from '../common/Button';
import APIKey from '../common/APIKey';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { updateUserInfo } from '../../slices/auth';
import { closeModal } from '../../slices/modals';
import { useNavigate } from 'react-router-dom';

const productOptions = [
  {
    id: 'inference',
    title: 'AI Inference',
    description:
      'Use Hyperbolic API to integrate any model into your application.',
    image: inferenceImage,
  },
  {
    id: 'marketplace',
    title: 'GPU Cloud',
    description:
      'Access enormous GPU resources easily with SSH for your projects.',
    image: marketplaceImage,
  },
];

const OnboardingModal = ({ onClose }: { onClose: () => void }) => {
  const { user, userInfo } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  return (
    <div
      id="onboarding-modal"
      className="flex flex-col items-center gap-3 py-2 md:p-4"
    >
      <ModalClose onClose={onClose} />
      <h2>Welcome{user?.displayName ? ` ${user?.displayName}` : ''}! 🎊</h2>
      <div className="text-sm text-black text-centers">
        Here's your API key. Congrats on getting&nbsp;
        <span className="font-semibold text-theme-primary-600">$10.00</span>
        &nbsp;in free credits!
      </div>
      <div className="my-3 flex flex-col gap-3 w-full items-center">
        <APIKey apiKey={userInfo?.api_key} containerClassName="rounded-lg" />
        <div className="text-sm text-center">
          You can also access this later in Settings.
        </div>
      </div>
      <div className="flex w-full gap-3 md:gap-5 mb-3">
        {productOptions.map((opt) => (
          <div
            key={opt.id}
            role="button"
            className="transition flex-1 flex flex-col rounded-lg border border-theme-primary-100 shadow-theme-02 gap-3 p-4 items-center hover:border-theme-primary-600"
            onClick={async () => {
              if (user?.uid) {
                await dispatch(
                  updateUserInfo({
                    userId: user?.uid,
                    update: { onboarded_for: opt.id },
                  })
                ).unwrap();
              }
              dispatch(closeModal());
              if (opt.id === 'inference') {
                navigate('/models');
              } else {
                navigate('/compute');
              }
            }}
          >
            <div className="text-lg text-black font-semibold">{opt.title}</div>
            <div className="text-theme-neutral-700 text-sm text-center font-light">
              {opt.description}
            </div>
            <img src={opt.image} style={{ width: 167 }} />
          </div>
        ))}
      </div>
      <Button onClick={onClose}>Get Started</Button>
    </div>
  );
};

export default OnboardingModal;

export const defaultStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    maxWidth: '650px',
    inset: 0,
    width: '66.6%',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: 0,
    filter:
      'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.08))',
    borderRadius: 12,
  },
};

export const fullScreenStyles = {
  overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
  content: {
    width: '100%',
    inset: '0px',
    maxWidth: '100%',
    height: '100%',
    borderRadius: 0,
    filter: 'none',
  },
};

export const mobileStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end',
  },
  content: {
    width: '100%',
    inset: 'auto auto 0px auto',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '90%',
    borderRadius: '12px 12px 0 0',
    filter: 'none',
  },
};

import { useDispatch, useSelector } from 'react-redux';
import { changeImageParameter, getGeneratedImage } from '../../slices/models';
import Lightbox from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import 'yet-another-react-lightbox/styles.css';
import { useState } from 'react';
import ParamBadge from '../Models/ParamBadge';
import GridScanIcon from '../common/icons/GridScanIcon';
import GenerateImageButton from '../Models/GenerateImageButton';
import CopyButton from '../common/CopyButton';
import { RootState } from '../../store';
import { LuBookmarkPlus } from 'react-icons/lu';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';
import { BsArrowDownCircle } from 'react-icons/bs';
import { downloadFile } from '../../utils/download';

const GeneratedImageModal = ({
  imagesId,
  onClose,
}: {
  imagesId: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const generatedImage = useSelector((state: RootState) =>
    getGeneratedImage(state, imagesId)
  );
  const [index, setIndex] = useState(0);

  const updateIndex = ({ index: current }: { index: number }) =>
    setIndex(current);
  const slides = generatedImage?.images.map((image) => ({
    src: `data:image/png;base64,${image.image}`,
    height: 400,
    width: 400,
  }));
  return (
    <div className="flex flex-col h-full">
      <ModalClose onClose={onClose} />
      <h2 className="mb-6">Generated Image</h2>
      <div className="flex flex-col lg:flex-row lg:overflow-y-hidden gap-4">
        <div className="w-full lg:w-1/2">
          {generatedImage && generatedImage?.images.length > 1 ? (
            <Lightbox
              index={index}
              slides={slides}
              plugins={[Inline]}
              on={{
                view: updateIndex,
              }}
              carousel={{
                padding: 0,
                spacing: 0,
                imageFit: 'cover',
              }}
              inline={{
                style: {
                  width: '50%',
                  maxWidth: '500px',
                  aspectRatio: '3 / 2',
                  margin: '0 auto',
                },
              }}
            />
          ) : (
            <img
              src={`data:image/png;base64,${generatedImage?.images[0].image}`}
              height={400}
              width={400}
              className="rounded-lg shadow-theme-01"
            />
          )}
        </div>
        <div className="flex flex-col gap-4 w-full lg:w-1/2 text-sm">
          <div className="flex flex-col gap-4 px-4 lg:overflow-y-auto">
            <div className="flex gap-2">
              <ParamBadge
                text={`${generatedImage?.params.height} x ${generatedImage?.params.width}`}
                icon={<GridScanIcon />}
              />
              <ParamBadge
                text="Download"
                icon={<BsArrowDownCircle />}
                onClick={() => {
                  if (generatedImage?.images[0].image) {
                    downloadFile(
                      generatedImage?.images[0].watermarkedImage ||
                        `data:image/png;base64,${generatedImage?.images[0].image}`,
                      'stable-diffusion.png'
                    );
                  }
                }}
              />
            </div>
            <div>
              <div className="text-sm text-black mb-2">Prompt</div>
              <div className="border border-theme-primary-200 rounded-lg py-2 px-4 flex items-center justify-between">
                <div className="truncate">
                  {generatedImage?.params.prompt || 'None'}
                </div>
                <CopyButton
                  id={`image-${imagesId}-prompt`}
                  copyText={generatedImage?.params.prompt || ''}
                />
              </div>
            </div>
            <div>
              <div className="text-sm text-black mb-2">Negative Prompt</div>
              <div className="border border-theme-primary-200 rounded-lg py-2 px-4 flex items-center justify-between">
                {generatedImage?.params.negativePrompt || 'None'}
                {generatedImage?.params.negativePrompt && (
                  <CopyButton
                    id={`image-${imagesId}-negativePrompt`}
                    copyText={generatedImage?.params.negativePrompt}
                  />
                )}
              </div>
            </div>
            <div>
              <div className="text-sm text-black mb-2">Steps</div>
              <div className="border border-theme-primary-200 rounded-lg py-2 px-4">
                {generatedImage?.params.steps}
              </div>
            </div>
            <div>
              <div className="text-sm text-black mb-2">CFG Scale</div>
              <div className="border border-theme-primary-200 rounded-lg py-2 px-4">
                {generatedImage?.params.cfgScale}
              </div>
            </div>
            {generatedImage?.params.image && (
              <>
                <div>
                  <div className="text-sm text-black mb-2">Reference Image</div>
                  <img
                    src={`data:image/png;base64,${generatedImage?.params.image}`}
                    className="w-20 h-20 margin-x-auto"
                  />
                </div>
                <div>
                  <div className="text-sm text-black mb-2">Strength</div>
                  <div className="border border-theme-primary-200 rounded-lg py-2 px-4">
                    {generatedImage?.params.strength}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex gap-2 px-4 mb-3 mt-2">
            <GenerateImageButton
              text="Generate again"
              className="flex-1"
              overrideParams={generatedImage?.params}
              onClick={onClose}
            />
            <Button
              variant="outline"
              className="flex items-center justify-center flex-1"
              onClick={() => {
                if (generatedImage?.images[0].image) {
                  dispatch(
                    changeImageParameter({ id: 'enableReference', value: true })
                  );
                  dispatch(
                    changeImageParameter({
                      id: 'image',
                      value: generatedImage?.images[0].image,
                    })
                  );
                  dispatch(
                    changeImageParameter({ id: 'strength', value: 0.8 })
                  );
                  onClose();
                }
              }}
            >
              <LuBookmarkPlus className="mr-2" size={24} />
              Reference
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratedImageModal;

import { Middleware, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { createLogger } from 'redux-logger';
import authReducer from './slices/auth';
import chatReducer from './slices/chat';
import modalsReducer from './slices/modals';
import machinesReducer from './slices/machines';
import instancesReducer from './slices/instances';
import modelsReducer from './slices/models';
import usageReducer from './slices/usage';

const logger = createLogger({
  collapsed: true,
});

const middlewares: Middleware[] = [];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: {
    auth: authReducer,
    chat: chatReducer,
    modals: modalsReducer,
    machines: machinesReducer,
    instances: instancesReducer,
    models: modelsReducer,
    usage: usageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;

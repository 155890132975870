import { IoImageOutline } from 'react-icons/io5';
import { GeneratedImage, changeImageParameter } from '../../slices/models';
import GridScanIcon from '../common/icons/GridScanIcon';
import ParamBadge from './ParamBadge';
import { FiArrowDownCircle } from 'react-icons/fi';
import { downloadFile } from '../../utils/download';
import { FaChevronRight } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import CopyButton from '../common/CopyButton';
import { LuBookmarkPlus, LuBoxes } from 'react-icons/lu';
import { Tooltip } from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles } from '../../utils/modals';
import { format } from 'date-fns';

const GeneratedImageSet = ({ imageSet }: { imageSet: GeneratedImage }) => {
  const dispatch = useDispatch();
  const avgInfTime = imageSet.inference_time / imageSet.images.length;
  return (
    <div id={imageSet.id} className="flex flex-col mb-8">
      <div className="mb-4">
        <div className="text-sm flex flex-col xl:flex-row text-theme-neutral-700 mb-4 gap-1 flex-wrap">
          <div className="flex items-center">
            <div className="flex flex-wrap">
              <span className="text-theme-primary-600">Prompt:</span>&nbsp;
              <span>{imageSet.params.prompt}</span>&nbsp;
            </div>
            <CopyButton
              id={imageSet.id}
              copyText={imageSet.params.prompt}
              className="mx-2 text-theme-neutral-700 hover:text-theme-neutral-600 span"
              tooltipText="Prompt copied!"
            />
          </div>
          <div className="flex items-center">
            <span className="text-theme-primary-600">Negative prompt:</span>
            &nbsp;
            <span>{imageSet.params.negativePrompt || 'None'}</span>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {imageSet.params.modelName && (
            <ParamBadge
              text={imageSet.params.modelName}
              icon={<LuBoxes size={14} />}
            />
          )}
          <ParamBadge
            text={imageSet.images.length}
            icon={<IoImageOutline size={14} />}
          />
          <ParamBadge
            text={`${imageSet.params.height} x ${imageSet.params.width}`}
            icon={<GridScanIcon />}
          />
          <ParamBadge
            text="More"
            icon={<FaChevronRight className="text-theme-primary-600" />}
            onClick={() =>
              dispatch(
                openModal({
                  name: ModalName.GeneratedImageModal,
                  ...(isMobile
                    ? {
                        styles: fullScreenStyles,
                      }
                    : {
                        styles: {
                          overlay: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          },
                          content: {
                            position: 'relative',
                            maxWidth: '860px',
                            inset: 0,
                          },
                        },
                      }),
                  props: {
                    imagesId: imageSet.id,
                  },
                })
              )
            }
          />
        </div>
      </div>
      <div className="flex">
        <div className="w-[3px] rounded-full mr-4 bg-theme-primary-600" />
        <div className="flex flex-col">
          <div className="flex gap-3">
            {imageSet.images.map((image, i) => (
              <div key={`image-set-${i}`} className="relative group/bg">
                <img
                  key={`image-set-${i}`}
                  src={`data:image/png;base64,${image.image}`}
                  className="rounded-lg"
                  style={{
                    height: 320,
                  }}
                />
                <div
                  role="button"
                  className="absolute inset-0 hidden bg-black bg-opacity-50 group-hover/bg:flex items-end transition rounded-lg"
                  onClick={() =>
                    dispatch(
                      openModal({
                        name: ModalName.GeneratedImageModal,
                        styles: {
                          overlay: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          },
                          content: {
                            position: 'relative',
                            maxWidth: '860px',
                            inset: 0,
                          },
                        },
                        props: {
                          imagesId: imageSet.id,
                        },
                      })
                    )
                  }
                >
                  <div className="flex ml-4 mb-4 gap-2">
                    <button
                      className="hover:border-theme-primary-600 group/button cursor-pointer transition-color"
                      data-tooltip-id={`info-download-${imageSet.id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadFile(
                          image.watermarkedImage ||
                            `data:image/png;base64,${image.image}`,
                          'stable-diffusion.png'
                        );
                      }}
                    >
                      <FiArrowDownCircle
                        className="text-white group-hover/button:text-theme-primary-600"
                        size={24}
                      />
                      <Tooltip
                        id={`info-download-${imageSet.id}`}
                        className="z-10"
                        place="top"
                      >
                        Download
                      </Tooltip>
                    </button>
                    <button
                      className="hover:border-theme-primary-600 group/button cursor-pointer transition-color"
                      data-tooltip-id={`info-reference-${imageSet.id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          changeImageParameter({
                            id: 'enableReference',
                            value: true,
                          })
                        );
                        dispatch(
                          changeImageParameter({
                            id: 'image',
                            value: image.image,
                          })
                        );
                        dispatch(
                          changeImageParameter({ id: 'strength', value: 0.8 })
                        );
                      }}
                    >
                      <LuBookmarkPlus
                        className="text-white group-hover/button:text-theme-primary-600"
                        size={24}
                      />
                      <Tooltip
                        id={`info-reference-${imageSet.id}`}
                        className="z-10"
                        place="top"
                      >
                        Use as reference image
                      </Tooltip>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-xs mt-2 flex items-center">
            <div className="mr-2">
              {format(imageSet.created, 'h:mm:ss a')}{' '}
              {format(imageSet.created, 'M/dd/yyyy')}
            </div>
            <span className="text-theme-primary-600">
              {Math.round(avgInfTime * 100) / 100} secs
            </span>
            &nbsp;/&nbsp;image
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratedImageSet;

import Card from '../../common/Card';
import { AddFunds } from '../../common/AddFunds';
import HorizontalLine from '../../common/HorizontalLine';
import BillingItemsGPUMarketplace from './BillingItems/BillingItemsGPUMarketplace';
import BillingItemsPurchaseHistory from './BillingItems/BillingItemsPurchaseHistory';
import CheckoutStatus from './CheckoutStatus';
// import BillingItemsDailyActivity from './BillingItems/BillingItemsRecentActivity';
// import BillingItemsAIInference from './BillingItems/BillingItemsAIInference';

const BillingHome = () => {
  return (
    <div className="flex flex-col w-full px-6 lg:px-16">
      <h2 className="font-semibold mb-8 text-theme-neutral-700">Billing</h2>
      <div className="flex flex-col items-center gap-8 mb-8">
        <HorizontalLine />
        <Card
          noHover
          className="w-full self-start flex bg-theme-neutral-50 border-none"
        >
          <AddFunds />
        </Card>
        <HorizontalLine />
        <div className="w-full flex flex-col md:flex-row justify-between gap-8">
          <BillingItemsPurchaseHistory />
          {/* <BillingItemsDailyActivity /> */}
        </div>
        <HorizontalLine />
        <div className="w-full flex flex-col md:flex-row justify-between gap-8">
          {/* <BillingItemsAIInference /> */}
          <BillingItemsGPUMarketplace />
        </div>
      </div>
      <CheckoutStatus />
    </div>
  );
};

export default BillingHome;

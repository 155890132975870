import { useSelector } from 'react-redux';
import { getAuthStatus, getUser, getUserInfo } from '../slices/auth';
import { ResponseStatus } from '../utils/types';

export enum UserStatus {
  LoggedOut = 'LoggedOut',
  LoggedIn = 'LoggedIn',
  LoggingIn = 'LoggingIn',
}

const getUserStatus = (
  user: any,
  accessToken: string | null,
  isLoading: boolean
): UserStatus => {
  if (user) {
    return UserStatus.LoggedIn;
  } else if (accessToken || isLoading) {
    return UserStatus.LoggingIn;
  }
  return UserStatus.LoggedOut;
};

const useUser = () => {
  const user = useSelector(getUser);
  const userInfo = useSelector(getUserInfo);
  const authStatus = useSelector(getAuthStatus);
  const authToken = localStorage.getItem('authToken');
  return {
    user,
    authToken,
    userInfo,
    status: getUserStatus(
      user,
      authToken,
      authStatus === ResponseStatus.Loading
    ),
  };
};

export default useUser;

import { DateRange } from 'react-day-picker';
import Dropdown, {
  DropdownButton,
  DropdownOverlay,
} from '../../common/Dropdown';
import DurationOverlay from './DurationOverlay';
import { format } from 'date-fns';
import { FiXCircle } from 'react-icons/fi';
import twClassnames from '../../../utils/classnames';

const DurationDropdown = ({
  range,
  onChangeRange,
}: {
  range: DateRange | undefined;
  onChangeRange: (dateRange: DateRange | undefined) => void;
}) => {
  const selectedRange =
    range?.from && range?.to
      ? `${format(range?.from, 'M/dd')}-${format(range?.to, 'M/dd')}`
      : '';
  return (
    <Dropdown className="inline-block">
      <DropdownButton
        className={twClassnames(
          'border-0 bg-theme-primary-50 px-5 py-3 text-theme-neutral-600 hover:bg-theme-primary-100',
          { 'py-2 px-2.5': !!selectedRange }
        )}
      >
        {selectedRange ? (
          <div className="rounded-full bg-theme-primary-100 text-sm text-theme-primary-600 py-1 px-2.5 flex items-center cursor-pointer">
            <span className="mr-1">{selectedRange}</span>
            <FiXCircle
              size={20}
              className="hover:text-theme-primary-700"
              onClick={() => {
                onChangeRange(undefined);
              }}
            />
          </div>
        ) : (
          'Duration'
        )}
      </DropdownButton>
      <DropdownOverlay className="w-80">
        <DurationOverlay range={range} onChangeRange={onChangeRange} />
      </DropdownOverlay>
    </Dropdown>
  );
};

export default DurationDropdown;

import { useDispatch, useSelector } from 'react-redux';
import {
  addMessage,
  cancelAIResponse,
  getAIResponse,
  getChatValue,
  getMessages,
  setChatValue,
} from '../../slices/chat';
import CompletionInputBar from './CompletionInputBar';
import { AppDispatch, RootState } from '../../store';
import useUser, { UserStatus } from '../../hooks/useUser';
import { usePostHog } from 'posthog-js/react';
import { useParams } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { getModel } from '../../slices/models';
import useAIResponse from '../../hooks/useAIResponse';
import twClassnames from '../../utils/classnames';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Markdown from 'react-markdown';
import '../common/Chat/Message.css';
import Button from '../common/Button';

const ModelCompletion = () => {
  const value = useSelector(getChatValue);
  const { status } = useUser();
  const posthog = usePostHog();
  const { modelId } = useParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const isBase = model?.subType === 'base';
  const isAuthenticated = status !== UserStatus.LoggedOut;
  const aiResponse = useSelector(getAIResponse);
  const chatMessages = useSelector(getMessages);
  const modelName = model?.model;
  const { handleAIResponse, isLoading } = useAIResponse({
    model,
    chatMessages,
    modelName,
  });
  const handleChatValue = (value: string) => dispatch(setChatValue(value));
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="lg:overflow-y-scroll -mx-10 flex-1">
      {chatMessages.length > 0 && (
        <div className="flex flex-col gap-3 px-10 mt-[14px]">
          {chatMessages.map((message) => (
            <div
              key={message.id}
              className={twClassnames('text-sm text-black flex gap-4', {
                'text-theme-neutral-600 font-medium': message.from === 'ai',
              })}
            >
              <Markdown
                className="message-markdown flex-1 flex flex-col gap-3 leading-6 break-words"
                components={{
                  code(props) {
                    const { children, className, node, ...rest } = props;
                    const newLines =
                      children?.toString().split('\n')?.length || 0;
                    const inline = newLines <= 1;
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline ? (
                      <SyntaxHighlighter
                        {...(rest as any)}
                        PreTag="div"
                        children={String(children).replace(/\n$/, '')}
                        language={match?.[1] || 'python'}
                        style={oneLight}
                        className="text-sm rounded-lg border"
                        wrapLongLines
                      />
                    ) : (
                      <code
                        className={twClassnames('inline-code', className)}
                        {...rest}
                      >
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {message.text}
              </Markdown>
              <div className="w-9" />
            </div>
          ))}
          {aiResponse.length > 0 && (
            <div
              key="ai-response"
              className="font-medium text-sm text-black text-theme-neutral-600 flex items-start gap-4"
            >
              <Markdown
                className="message-markdown flex-1 flex flex-col gap-3 leading-6 break-words"
                components={{
                  code(props) {
                    const { children, className, ...rest } = props;
                    const newLines =
                      children?.toString().split('\n')?.length || 0;
                    const inline = newLines <= 1;
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline ? (
                      <SyntaxHighlighter
                        {...(rest as any)}
                        PreTag="div"
                        children={String(children).replace(/\n$/, '')}
                        language={match?.[1] || 'python'}
                        style={oneLight}
                        className="text-sm rounded-lg border"
                        wrapLongLines
                      />
                    ) : (
                      <code
                        className={twClassnames('inline-code', className)}
                        {...rest}
                      >
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {aiResponse.join('')}
              </Markdown>
              <Button
                variant="link"
                type="button"
                className="group hover:bg-theme-primary-600 hover:text-white bg-theme-neutral-200 p-3 rounded-full duration-400 flex items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(cancelAIResponse());
                }}
              >
                <div className="h-3 w-3 rounded-sm bg-theme-primary-600 group-hover:bg-white" />
              </Button>
            </div>
          )}
        </div>
      )}
      {!isLoading && (
        <CompletionInputBar
          value={value}
          onChange={handleChatValue}
          disabled={isLoading || aiResponse.length > 0}
          onSubmit={(defaultText?: string) => {
            if (!isAuthenticated) return;
            const text = value || defaultText;
            const message = {
              text,
              created: Date.now(),
              id: uuidV4(),
              ...(isBase && { reset: true }),
            };
            posthog?.capture('Generate Text Clicked', {
              model: modelId,
            });
            dispatch(addMessage(message));
            handleAIResponse(text || '');
            handleChatValue('');
          }}
        />
      )}
    </div>
  );
};

export default ModelCompletion;

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../store';
import {
  fetchMarketplaceInstances,
  getFormattedMarketplaceInstance,
} from '../slices/instances';
import { openModal } from '../slices/modals';
import { ModalName } from '../components/modals';
import Button from '../components/common/Button';
import { InstanceBillingPeriod } from '../utils/types';
import useUser from '../hooks/useUser';
import AuthButton from '../components/AuthButton';
import { formatCurrency } from '../utils/value';
import { displayModelName } from '../utils/instances';
import { useEffect } from 'react';

const InstanceFieldDetail = ({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) => (
  <div className="flex mb-4">
    <div className="font-bold mr-2 text-theme-neutral-700">{label}:</div>
    <div>{value || 'N/A'}</div>
  </div>
);

const InstanceDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, authToken } = useUser();
  const { instanceId } = useParams();
  const instance = useSelector((state: RootState) =>
    getFormattedMarketplaceInstance(state, instanceId)
  );

  // todo: fetch instance from remote if not in redux
  useEffect(() => {
    if (authToken) {
      dispatch(fetchMarketplaceInstances());
    }
  }, [dispatch, authToken]);

  const {
    gpu,
    gpuRamGB,
    cpu,
    totalPrice,
    gpuCount,
    storageCapacity,
    ramCapacity,
  } = instance || {};

  const region = 'North America' || instance?.location?.region;
  const city = 'San Francisco' || instance?.location?.city;
  const country = 'US' || instance?.location?.country;

  const upload = instance?.network?.upload || 'N/A';
  const download = instance?.network?.download || 'N/A';
  const latency = instance?.network?.latency || 'N/A';
  const measuredAt = instance?.network?.measured_at || 'Today';

  const period =
    instance?.pricing.price?.period || InstanceBillingPeriod.hourly; // eh?

  // todo: show instance metadata?
  // todo: show availability

  return (
    <div className="flex flex-col w-full px-10">
      <h1>Instance</h1>
      <h2>Details</h2>
      <div>
        <InstanceFieldDetail label="Name" value={instance?.id || 'Unknown'} />
        <InstanceFieldDetail
          label="GPU model"
          value={displayModelName(gpu?.model)}
        />
        <InstanceFieldDetail label="Number of GPUs" value={gpuCount} />
        <InstanceFieldDetail label="GPU RAM" value={`${gpuRamGB} GB`} />
        <InstanceFieldDetail label="Instance RAM" value={ramCapacity} />
        <InstanceFieldDetail label="CPU model" value={cpu?.model} />
        <InstanceFieldDetail label="Storage capacity" value={storageCapacity} />
        <InstanceFieldDetail label="Network upload" value={`${upload} Mbps`} />
        <InstanceFieldDetail
          label="Network download"
          value={`${download} Mbps`}
        />
        <InstanceFieldDetail label="Network latency" value={`${latency}ms`} />
        <InstanceFieldDetail
          label="Network fields last measured at"
          value={measuredAt.toLocaleString()}
        />
        <InstanceFieldDetail
          label="Operating System (OS)"
          value={instance?.host_os}
        />
        <InstanceFieldDetail label="Price" value={formatCurrency(totalPrice)} />
        <InstanceFieldDetail label="Billing period" value={period} />
        <InstanceFieldDetail
          label="Location"
          value={`${city ? `${city}, ` : ''}${
            country ? `${country}, ` : ''
          }${region}`}
        />
        {user ? (
          <Button
            className="w-full max-w-32"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                openModal({
                  name: ModalName.ConfirmRentModal,
                  props: { instanceId },
                })
              );
            }}
          >
            Rent
          </Button>
        ) : (
          <AuthButton className="w-full max-w-32" />
        )}
      </div>
    </div>
  );
};

export default InstanceDetails;
